<div class="col-md-12">
  <br />
  <span>{{ "Manage_Skills" | translate }}</span>
  <br />
  <br />
  <div class="row">
     <!-- Expert Ratings -->
     <div class="col-md-4">
      <div style="border-radius: 8px; background-color: #009EE0; box-shadow: 0 2px 4px 0 rgba(0,0,0,0.14)">
      <br />
        <span style="text-transform: uppercase; width: 90%; margin-left: 5%; color: white"><strong>Look your expert skills</strong></span>
        <br />
        <br />
        <span style="width: 90%; margin-left: 5%; color: white">AWS Lambda Functions</span>
        <select class="custom-select" style="width: 90%; margin-left: 5%">
          <option selected>Expert</option>
          <option value="1">None</option>
          <option value="2">Beginner</option>
          <option value="3">Intermediate</option>
          <option value="4">Advanced</option>
          <option value="5">Expert</option>
        </select>
        <br />
        <span style="width: 90%; margin-left: 5%; color: white">Angular</span>
        <select class="custom-select" style="width: 90%; margin-left: 5%">
          <option selected>Expert</option>
          <option value="1">None</option>
          <option value="2">Beginner</option>
          <option value="3">Intermediate</option>
          <option value="4">Advanced</option>
          <option value="5">Expert</option>
        </select>
        <br />
        <span style="width: 90%; margin-left: 5%; color: white">JAVA</span>
        <select class="custom-select" style="width: 90%; margin-left: 5%">
          <option selected>Expert</option>
          <option value="1">None</option>
          <option value="2">Beginner</option>
          <option value="3">Intermediate</option>
          <option value="4">Advanced</option>
          <option value="5">Expert</option>
        </select>
        <br />
        <span style="width: 90%; margin-left: 5%; color: white">Android Kotlin</span>
        <select class="custom-select" style="width: 90%; margin-left: 5%">
          <option selected>Expert</option>
          <option value="1">None</option>
          <option value="2">Beginner</option>
          <option value="3">Intermediate</option>
          <option value="4">Advanced</option>
          <option value="5">Expert</option>
        </select>
        <br />
        <p>&nbsp;</p>
      </div>
    </div>
    <!-- Last Ratings -->
    <div class="col-md-4">
      <div style="border-radius: 8px; background-color: #FFFFFF; box-shadow: 0 2px 4px 0 rgba(0,0,0,0.14);">
        <br />
        <span style="text-transform: uppercase; width: 90%; margin-left: 5%"><strong>Your last ratings</strong></span>
        <br />
        <br />
        <span style="width: 90%; margin-left: 5%">My skill</span>
        <br />
        <select class="custom-select" style="width: 90%; margin-left: 5%">
          <option selected>Intermediate</option>
          <option value="1">None</option>
          <option value="2">Beginner</option>
          <option value="3">Intermediate</option>
          <option value="4">Advanced</option>
          <option value="5">Expert</option>
        </select>
        <span style="width: 90%; margin-left: 5%">My skill</span>
        <br />
        <select class="custom-select" style="width: 90%; margin-left: 5%">
          <option selected>Intermediate</option>
          <option value="1">None</option>
          <option value="2">Beginner</option>
          <option value="3">Intermediate</option>
          <option value="4">Advanced</option>
          <option value="5">Expert</option>
        </select>
        <span style="width: 90%; margin-left: 5%">My skill</span>
        <br />
        <select class="custom-select" style="width: 90%; margin-left: 5%">
          <option selected>Intermediate</option>
          <option value="1">None</option>
          <option value="2">Beginner</option>
          <option value="3">Intermediate</option>
          <option value="4">Advanced</option>
          <option value="5">Expert</option>
        </select>
        <span style="width: 90%; margin-left: 5%">My skill</span>
        <br />
        <select class="custom-select" style="width: 90%; margin-left: 5%">
          <option selected>Intermediate</option>
          <option value="1">None</option>
          <option value="2">Beginner</option>
          <option value="3">Intermediate</option>
          <option value="4">Advanced</option>
          <option value="5">Expert</option>
        </select>
        <br />
        <br />
      </div>
    </div>
    <!-- Empty Ratings -->
    <div class="col-md-4">
      <div style="border-radius: 8px; background-color: #FFCC00; box-shadow: 0 2px 4px 0 rgba(0,0,0,0.14)">
      <br />
        <span style="text-transform: uppercase; width: 90%; margin-left: 5%"><strong>Rate your empty skills</strong></span>
        <br />
        <br />
        <span style="width: 90%; margin-left: 5%">My skill</span>
        <select class="custom-select" style="width: 90%; margin-left: 5%">
          <option selected>Select rate...</option>
          <option value="1">None</option>
          <option value="2">Beginner</option>
          <option value="3">Intermediate</option>
          <option value="4">Advanced</option>
          <option value="5">Expert</option>
        </select>
        <br />
        <span style="width: 90%; margin-left: 5%">My skill</span>
        <select class="custom-select" style="width: 90%; margin-left: 5%">
          <option selected>Select rate...</option>
          <option value="1">None</option>
          <option value="2">Beginner</option>
          <option value="3">Intermediate</option>
          <option value="4">Advanced</option>
          <option value="5">Expert</option>
        </select>
        <br />
        <span style="width: 90%; margin-left: 5%">My skill</span>
        <select class="custom-select" style="width: 90%; margin-left: 5%">
          <option selected>Select rate...</option>
          <option value="1">None</option>
          <option value="2">Beginner</option>
          <option value="3">Intermediate</option>
          <option value="4">Advanced</option>
          <option value="5">Expert</option>
        </select>
        <br />
        <span style="width: 90%; margin-left: 5%">My skill</span>
        <select class="custom-select" style="width: 90%; margin-left: 5%">
          <option selected>Select rate...</option>
          <option value="1">None</option>
          <option value="2">Beginner</option>
          <option value="3">Intermediate</option>
          <option value="4">Advanced</option>
          <option value="5">Expert</option>
        </select>
        <br />
        <p>&nbsp;</p>
      </div>
    </div>
  </div>
</div>
