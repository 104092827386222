import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {Skill} from '../../../models/skill.model';
import {SkillNeed} from '../../../models/skill-need';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SkillsService} from '../../../services/entities/skills.service';
import {ApiService} from '../../../services/api.service';
import {RatingsService} from '../../../services/entities/ratings.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatRadioChange} from '@angular/material/radio';
import {Application} from 'src/app/models/application.model';
import {ApplicationsService} from 'src/app/services/entities/applications.service';
import {UsergroupsService} from 'src/app/services/entities/usergroups.service';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Usergroup} from 'src/app/models/usergroup.model';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TargetRequirement } from 'src/app/models/target-requirement';

@Component({
  selector: 'app-dashboard-teams-add-needs-dialog',
  templateUrl: './dashboard-teams-add-needs-dialog.component.html',
  styleUrls: ['./dashboard-teams-add-needs-dialog.component.css']
})
export class DashboardTeamsAddNeedsDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<DashboardTeamsAddNeedsDialogComponent>,
              private ratingsService: RatingsService,
              private apiService: ApiService,
              private changeDetectorRefs: ChangeDetectorRef,
              private snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              private skillsService: SkillsService,
              private applicationService: ApplicationsService,
              private usergroupsService: UsergroupsService,
              @Inject(MAT_DIALOG_DATA) data) {
  }

  mainFormGroup: FormGroup;

  currentStep = 0;

  currentUser: any;

  typeOfDialog = '';

  choice = '';

  noapplication: Application = {
    APPLICATION_ID: '',
    label: '--'
  };

  apps = new FormControl();
  usergroups = new FormControl();

  Skills: Skill[] = [];

  Apps: Application[];

  Usergroups: Usergroup[];

  skillsform = new FormControl();


  selectedSkills: Skill[] = new Array<Skill>();

  filteredSkills: Observable<Skill[]>;
  lastFilter = '';


  // POSITION OF SNACKBAR
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';



  selectedValue = 0;


  


  retrieveAllSkillsName(): void {

    let usergroup = '';
    let app = '';

    const user_group = this.usergroups.value;

    const application = this.apps.value;

    console.log(user_group);
    console.log(application);

    if (user_group != null) {
      usergroup = this.usergroups.value.USER_GROUP_ID;
    }
    
    if (application != null){
      app = this.apps.value.APPLICATION_ID;
    }

    

    if (usergroup != null) {
          this.usergroupsService.listUnnecessarySkills(usergroup,app)
      .subscribe(
        data => {
           this.Skills = data;
         },
        error => {
         console.log(error);
        });
    }

  }

  retrieveAllAppsName(): void {
    this.applicationService.getAllApplications()
      .subscribe(
        data => {
          this.Apps = data;
        },
        error => {
          console.log(error);
        });
  }

  retrieveAllUserGroupsName(): void {
    this.usergroupsService.getAllUsergroups()
      .subscribe(
        data => {
          this.Usergroups = data;
        },
        error => {
          console.log(error);
        });
  }


  ngOnInit(): void {
    this.retrieveAllUserGroupsName();
    this.retrieveAllAppsName();

    this.filteredSkills = this.skillsform.valueChanges.pipe(
      startWith<string | Skill[]>(''),
      map(value => typeof value === 'string' ? value : this.lastFilter),
      map(filter => this.filter(filter))
    );

    this.mainFormGroup = this.formBuilder.group({
      formCount: 1,
      stepData: this.formBuilder.array([])
    });

  }

  addInput(currentIndex): void {
    const arrayControl = <FormArray>this.mainFormGroup.controls["stepData"];
    let newGroup = this.formBuilder.group({
      firstCtrl: [''],
      secondCtrl: [''],
      thirdCtrl: ['']
    });
    arrayControl.push(newGroup);
  }
  delInput(index: number): void {
    const arrayControl = <FormArray>this.mainFormGroup.controls["stepData"];
    arrayControl.removeAt(index);
  }


  closeDialog() {
    this.dialogRef.close();
  }

  choiceMade() {
    let isOk = !(this.usergroups.value == null || this.mainFormGroup.controls["stepData"].value[0] == undefined || this.mainFormGroup.controls["stepData"].value[0].firstCtrl == '' || this.mainFormGroup.controls["stepData"].value[0].secondCtrl == '' || this.mainFormGroup.controls["stepData"].value[0].thirdCtrl == '');
    return isOk;
  }

  saveRating() {

    let skills: SkillNeed[];
    skills = [];
    let i = 0;

    this.mainFormGroup.controls["stepData"].value.forEach(element => {

      var targetrequirement: TargetRequirement[] = [];

      var requirement = {
        operator: element.secondCtrl,
        required_value: element.firstCtrl,
        required_effective: element.thirdCtrl
      }

      targetrequirement.push(requirement);


      let skillneed: SkillNeed = {
      SKILL_ID: this.skillsform.value[i].SKILL_ID,
      active: this.skillsform.value[i].active ,
      category: this.skillsform.value[i].category,
      classification: this.skillsform.value[i].classification,
      label: this.skillsform.value[i].label,
      requirements: targetrequirement
      };
      skills.push(skillneed);
      i = i +1 ;
    });

    console.log(skills);


    const user_group = this.usergroups.value;

    let application = this.apps.value;

    if (application == null) {
      application = {};
    }
    

    const data = {
      user_group,
      application,
      skills
    };

    this.usergroupsService.addUserGroupNeed(data)
      .subscribe(
        (res: HttpResponse<Skill>) => {
          this.closeDialog();
          this.snackBar.open('A skill has been created', 'OK', {
            duration: 4000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['snackbar-success']
          });
        },
        (error: HttpErrorResponse) => {
          this.closeDialog();
          this.snackBar.open('ERROR for create a skill', 'OK', {
            duration: 4000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['snackbar-error']
          });
        }
      );
  }

  radioChange(event: MatRadioChange) {
    this.selectedValue = event.value;
  }

  setSteps() {
    console.log(this.skillsform.value);
    console.log("has been updated");
    this.mainFormGroup = this.formBuilder.group({
      formCount: 1,
      stepData: this.formBuilder.array([
       // this.formBuilder.group({
       //   name: ["", Validators.required]
       // })
      ])
    });
    /*
    this.stFormGroup = this.formBuilder.group({
      firstCtrl: ["", Validators.required]
    });
    this.ndFormGroup = this.formBuilder.group({
      secondCtrl: ["", Validators.required]
    });
    */
    let i = -1;
    this.skillsform.value.forEach(element => {
      this.addInput(i);
      i = i + 1;
    });
  }

  filter(filter: string): Skill[] {
    this.lastFilter = filter;
    if (filter) {
      return this.Skills.filter(option => {
        return option.label.toLowerCase().indexOf(filter.toLowerCase()) >= 0
          || option.classification.toLowerCase().indexOf(filter.toLowerCase()) >= 0
          || option.category.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      });
    } else {
      return this.Skills.slice();
    }
  }

  displayFn(value: Skill[] | string): string | undefined {
    let displayValue: string;
    if (Array.isArray(value)) {
      value.forEach((skill, index) => {
        if (index === 0) {
          displayValue = skill.label + ' ' + skill.classification + ' ' + skill.category;
        } else {
          displayValue += ', ' + skill.label + ' ' + skill.classification + ' ' + skill.category;
        }
      });
    } else {
      displayValue = value;
    }
    return displayValue;
  }

  optionClicked(event: Event, skill: Skill) {
    event.stopPropagation();
    this.toggleSelection(skill);
  }

  toggleSelection(skill: Skill) {
    skill.selected = !skill.selected;
    if (skill.selected) {
      this.selectedSkills.push(skill);
    } else {
      // tslint:disable-next-line:max-line-length
      const i = this.selectedSkills.findIndex(value => value.label === skill.label && value.classification === skill.classification && value.category === skill.category);
      this.selectedSkills.splice(i, 1);
    }

    this.skillsform.setValue(this.selectedSkills);
    this.setSteps();
  }

}

export class SelectCustomTriggerExample {

}



