import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {map, tap} from 'rxjs/operators';

const baseUrl = environment.API_BASE_URL;
const httpOptions = {
   headers: new HttpHeaders({
     'Content-Type': 'application/json',
     Authorization: sessionStorage.getItem('authToken')
   }),
  body: {}
};

@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {

  constructor(private http: HttpClient) { }

    getAllApplications(): Observable<any>{
      return this.http.get(`${baseUrl}application`, httpOptions);
    }

    createApplication(data): Observable<any>{
      return this.http.post(`${baseUrl}application`, data, httpOptions);
    }

    updateApplication(data): Observable<any>{
      httpOptions.body = data;
      return this.http.request<string>('put', `${baseUrl}application`, httpOptions).pipe(
        map((result: any) => result as string),
        tap((result: string) => {
          console.log('response from server:', result);
        }
      ));
    }

    deleteApplication(id): Observable<any>{
      httpOptions.body = {APPLICATION_ID: id};
      return this.http.request<string>('delete', `${baseUrl}application`, httpOptions).pipe(
        map((result: any) => result as string), // fix typescript typing
        tap((result: string) => {
            console.log('response from server:', result);
          }
        ));
    }
}
