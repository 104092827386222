<div class="container">

    <br />

    <span class="text name">{{ firstName }} {{ lastName }}</span>

    <br />
    <br />
    <br />

    <span class="infos">{{ "Profile.Title" | translate }}</span>

    <br />
    <br />

    <div class="informations col-md-12">

        <br />

        <label for="firstname">{{ "Profile.FirstName" | translate }}</label>
        <span class="input-text champ text">{{ firstName }}</span>

        <br />

        <label for="lastname">{{ "Profile.LastName" | translate }}</label>
        <span class="input-text champ text">{{ lastName }}</span>

        <br />

        <label for="email">{{ "Profile.Email" | translate }}</label>
        <span class="input-text champ">{{ email }}</span>

        <br />

        <div class="row">
            
            <div class="col-6">
                <label for="Language">Language</label>
                <select class="input-text champ" [(ngModel)]="profile.translation_language" (change)="updateProfile()" id="language" name="language">
                    <option value="en">English 🇬🇧/🇺🇸</option>
                    <option value="fr">French 🇫🇷</option>
                    <option value="de">German 🇩🇪</option>
                </select>
            </div>

            <br />

            <div class="col-6">
                <label for="work_time">Work Time</label>
                <select class="input-text champ" [(ngModel)]="profile.work_time" (change)="updateProfile()" id="language" name="language">
                    <option value="100">100 %</option>
                    <option value="80">80 %</option>
                    <option value="70">70 %</option>
                    <option value="60">60 %</option>
                    <option value="50">50 %</option>
                </select>
            </div>

            <br />

        </div>

        <div class="row">
            
            <div class="col-6">
                <label for="group">Role</label>
                <span class="input-text champ">{{ role }}</span>
            </div>

            <br />

            <div class="col-6">
                <label for="status">Status</label>
                <span class="input-text champ">{{ status }}</span>
            </div>

        </div>

        <br />
    </div>

</div>
