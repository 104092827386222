import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SkillsService} from '../../../services/entities/skills.service';
import {ApiService} from '../../../services/api.service';
import {RatingsService} from '../../../services/entities/ratings.service';
import {FormControl} from '@angular/forms';
import {MatRadioChange} from '@angular/material/radio';
import {UserService} from 'src/app/services/entities/user.service';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Ability, SummarizedAbility} from '../../../models/ability.model';
import {SharedService} from '../../../services/shared.service';


@Component({
  selector: 'app-dashboard-user-update-ratings-dialog',
  templateUrl: './dashboard-user-update-ratings-dialog.component.html',
  styleUrls: ['./dashboard-user-update-ratings-dialog.component.css']
})
export class DashboardUserUpdateRatingsDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<DashboardUserUpdateRatingsDialogComponent>,
              private ratingsService: RatingsService,
              private apiService: ApiService,
              private changeDetectorRefs: ChangeDetectorRef,
              private snackBar: MatSnackBar,
              private skillsService: SkillsService,
              private usersService: UserService,
              private sharedService: SharedService,
              @Inject(MAT_DIALOG_DATA) data) {

    this.stars = 0;
    this.clicked = false;
  }


  currentUser: any;

  typeOfDialog = '';

  choice = '';

  abilities = new FormControl();

  Abilities: SummarizedAbility[];


  /* skill: Skill = {
     SKILL_ID: '',
     label: '',
     active: true,
     last_update_user: '',
     last_update_date: '',
     class: '',
     class: ''
   }; */

  // POSITION OF SNACKBAR
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  filter: any;
  selectedValue = 0;

  stars: number;
  clicked: boolean;

  addClick(IsClicked: boolean) {
    this.clicked = IsClicked;
  }

  addStars(StarsNb: number) {
    this.stars = StarsNb;
  }

  retrieveAbilities(): void {
    this.usersService.getUserLastAbilities()
      .subscribe(
        data => {
          this.Abilities = data;
        },
        error => {
          console.log(error);
        });
  }


  ngOnInit(): void {
    this.retrieveAbilities();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  choiceMade() {
    return !(this.stars === 0 || this.abilities.value == null);
  }

  saveRating(skills: any) {
    const data = {
      abilities: this.abilities.value,
      value: this.stars
    };
    this.usersService.updateAbilities(data)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.closeDialog();
          this.snackBar.open('Skills have been rated', 'OK', {
            duration: 4000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['snackbar-success']
          });
          this.sharedService.callFunctionEvent();
        },
        (error: HttpErrorResponse) => {
          this.closeDialog();
          this.snackBar.open('ERROR for rating these skills', 'OK', {
            duration: 4000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['snackbar-error']
          });
        }
      );
  }

  radioChange(event: MatRadioChange) {
    this.selectedValue = event.value;
  }

  setStars(amount: number) {
    if (this.clicked !== true) {
      this.stars = amount;
    }

  }

  setStarsClick(amount: number) {
      this.stars = amount;
  }

}
