import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {Ability} from '../../../models/ability.model';
import {ApplicationSkillTarget} from '../../../models/application-skill-target.model';
import {User} from '../../../models/user.model';
import {Application} from '../../../models/application.model';


@Component({
  selector: 'app-dashboard-manager-details',
  templateUrl: './dashboard-manager-details.component.html',
  styleUrls: ['./dashboard-manager-details.component.css']
})
export class DashboardManagerDetailsComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() dataSource: MatTableDataSource<User | Application>;
  mobile = false;

  constructor(public dialog: MatDialog,
              private snackBar: MatSnackBar) {
  }

  displayedColumns: string[] = ['username'];

  Ratings: Ability[];
  ApplicationSkillTargets: ApplicationSkillTarget[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

// POSITION OF SNACKBAR
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // const sortState: Sort = { active: 'lastHistoryDate', direction: 'desc' };
    // this.sort.active = sortState.active;
    // this.sort.direction = sortState.direction;
    // this.sort.sortChange.emit(sortState);
  }

  /*lastHistory(histories: History[]) {
    let result = histories[0];
    for (const history of histories) {
      if (history.date > result.date) {
        result = history;
      }
    }
    return result;
  }*/

// numberOfDaysSince(dateSent) {
//   const currentDate = new Date();
//   dateSent = new Date(dateSent);
//   return Math.floor((
//     Date.UTC(
//       currentDate.getFullYear(),
//       currentDate.getMonth(),
//       currentDate.getDate()
//     )
//     - Date.UTC(dateSent.getFullYear(),
//     dateSent.getMonth(),
//     dateSent.getDate()) ) / (1000 * 60 * 60 * 24)
//   );
// }

// attributeColor(date) {
//   const days = this.numberOfDaysSince(date);
//   if (days > 90) { return 'warn-3'; }
//   else if (days > 60) { return 'warn-2'; }
//   else if (days > 30) { return 'warn-1'; }
//   else { return 'warn-0'; }
// }

  ngOnInit(): void {
    console.log('members table', this.dataSource);
    if (window.screen.width <= 790) { // 768px portrait
      this.mobile = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this.dataSource = new MatTableDataSource(changes.members);
  }

}
