import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-ratings-dialog',
  templateUrl: './ratings-dialog.component.html',
  styleUrls: ['./ratings-dialog.component.css']
})
export class RatingsDialogComponent implements OnInit {

  showMsgSuccess: boolean = false;
  showMsgError: boolean = false;

  constructor(private dialogRef: MatDialogRef<RatingsDialogComponent>) { }

  ngOnInit(): void {
  }

  save(){
    this.showMsgSuccess = true;
    this.showMsgError = true;
  }

  close(){
    this.dialogRef.close();
  }

}
