<mat-card class="dashboard-card">
  <mat-card-header>
    <mat-card-title *ngIf="!(title === '')">
      {{title}}
      <br><br/>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="dashboard-card-content">
    <ng-content></ng-content>
  </mat-card-content>
</mat-card>
