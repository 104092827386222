import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Ability} from '../../../models/ability.model';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {UserService} from '../../../services/entities/user.service';
import {SharedService} from '../../../services/shared.service';

@Component({
  selector: 'app-dashboard-user-update-rating-dialog',
  templateUrl: './dashboard-user-update-rating-dialog.component.html',
  styleUrls: ['./dashboard-user-update-rating-dialog.component.css']
})
export class DashboardUserUpdateRatingDialogComponent implements OnInit {

  abilities: Ability[] = [{
    ABILITY_ID: '',
    skill: {
      SKILL_ID: '',
      active: true,
      last_update_date: '',
      last_update_user: '',
      category: '',
      classification: '',
      label: ''
    },
    ratings: [
      {
        RATING_ID: '',
        date: '',
        value: 0
      }
    ]
  }];
  newValue = 0;

  // POSITION OF SNACKBAR
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private dialogRef: MatDialogRef<DashboardUserUpdateRatingDialogComponent>,
              private changeDetectorRefs: ChangeDetectorRef,
              private snackBar: MatSnackBar,
              private userService: UserService,
              private sharedService: SharedService,
              @Inject(MAT_DIALOG_DATA) data) {
    this.abilities[0].ABILITY_ID = data.ability.ABILITY_ID;
    this.abilities[0].skill.label = data.ability.skill_label;
    this.abilities[0].skill.category = data.ability.skill_category;
    this.abilities[0].skill.classification = data.ability.skill_classification;
    this.abilities[0].ratings[0].date = data.ability.last_rating_date;
    this.abilities[0].ratings[0].value = data.ability.last_rating_value;
    this.newValue = data.newValue;
    console.log(data);
  }

  ngOnInit(): void {
  }

  saveAbility() {
    const data = {
      abilities: this.abilities,
      value: this.newValue
    };
    this.userService.updateAbilities(data)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.closeDialog();
          this.snackBar.open('Your new level has been changed', 'OK', {
            duration: 4000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['snackbar-success']
          });
          this.sharedService.callFunctionEvent();
        },
        (error: HttpErrorResponse) => {
          this.closeDialog();
          this.snackBar.open('ERROR for create new ability level', 'OK', {
            duration: 4000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['snackbar-error']
          });
        }
      );
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
