import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UsergroupsService } from 'src/app/services/entities/usergroups.service';

@Component({
  selector: 'app-dashboard-teams-add-members-dialog',
  templateUrl: './dashboard-teams-add-members-dialog.component.html',
  styleUrls: ['./dashboard-teams-add-members-dialog.component.css']
})
export class DashboardTeamsAddMembersDialogComponent implements OnInit {

  Users = [];

  team = 'ITE-BMDF';
  members = [
    {
      firstname: 'Clément',
      lastname: 'Cogo',
      status: 'Internal'
    },
    {
      firstname: 'Tristan',
      lastname: 'Michel',
      status: 'Internal'
    },
    {
      firstname: 'Garry',
      lastname: 'Verrier',
      status: 'Internal'
    },
    {
      firstname: 'Antoine',
      lastname: 'Blois',
      status: 'Internal'
    },
    {
      firstname: 'Baptiste',
      lastname: 'Roka',
      status: 'Internal'
    }
  ]

  memberstoadd = []

  constructor(private dialogRef: MatDialogRef<DashboardTeamsAddMembersDialogComponent>,
    private usergroupsService: UsergroupsService) {
   }


   retrieveUserWithoutGroups(): void {
    this.usergroupsService.listUserWithoutGroups()
      .subscribe(
        data => {
          this.Users = data;
        },
        error => {
          console.log(error);
        });
  }

  ngOnInit(): void {
    this.retrieveUserWithoutGroups();
    console.log(this.Users);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  addMember(member) {
    if (this.memberstoadd.includes(member)) {
      this.memberstoadd.splice(this.memberstoadd.indexOf(member),1);
    }
    else
    {
      this.memberstoadd.push(member);
    }
  }

  isMember(member){
    return this.memberstoadd.includes(member);
  }

  isEmpty(){
    if (this.memberstoadd.length < 1) {
      return true;
    }
    else
    {
      return false;
    }
  }

  saveMembers(){
    console.log(this.memberstoadd);
  }

}
