<mat-icon (click)="closeDialog()" type="button" style="color: #009EE0; float: right;" aria-label="Cancel_icon">cancel</mat-icon>
<br>
<ng-container>

  <div>
    <div>
      <h2 mat-dialog-title>{{ "Dashboards.User.RatingsTable.Dialog.Title" | translate }}</h2>
    </div>
  </div>
  <mat-dialog-content>
    <!--Skill-->
    <label class="input-label" >{{ 'Dashboards.User.RatingsTable.Dialog.Skill.Title' | translate }}</label>
    <div class="row">
      <div class="col-md-4">
        <label class="input-label" >{{ 'Dashboards.User.RatingsTable.Dialog.Skill.Name' | translate }}</label>
        <br>
        <h3 class="font-weight-bold">{{abilities[0].skill.label}}</h3>
      </div>

      <div class="col-md-4">
        <label class="input-label" >{{ 'Dashboards.User.RatingsTable.Dialog.Skill.Type' | translate }}</label>
        <br>
        <h3 class="font-weight-bold">{{abilities[0].skill.category}}</h3>
      </div>

      <div class="col-md-4">
        <label class="input-label" >{{ 'Dashboards.User.RatingsTable.Dialog.Skill.Category' | translate }}</label>
        <br>
        <h3 class="font-weight-bold">{{abilities[0].skill.classification}}</h3>
      </div>

        <!--Old Value-->
        <div class="col-md-6 m-auto">
          <label class="input-label" >{{ 'Dashboards.User.RatingsTable.Dialog.OldValue' | translate }}</label>
          <br>
          <app-star-rating [rating]="abilities[0].ratings[0].value"></app-star-rating>
          <br><br>
        </div>

        <!--New Value-->
        <div class="col-md-6 m-auto">
          <label class="input-label" >{{ 'Dashboards.User.RatingsTable.Dialog.NewValue' | translate }}</label>
          <br>
          <app-star-rating [rating]="newValue"></app-star-rating>
          <br><br>
        </div>

    </div>



  </mat-dialog-content>
</ng-container>
<mat-dialog-actions align="center">
  <button class="mat-raised-button button_cancel col-md-3" (click)="closeDialog()">{{ "Buttons.Cancel" | translate }}</button>
  &nbsp;&nbsp;&nbsp;&nbsp;
  <button class="mat-raised-button button_save col-md-3" (click)="saveAbility()">{{ "Buttons.Save" | translate }}</button>
</mat-dialog-actions>
