<mat-icon (click)="closeDialog()" type="button" style="color: #009EE0; float: right;" aria-label="Cancel_icon">cancel
</mat-icon>

    <div class="title">
        <h2 mat-dialog-title>Add members to your usergroup</h2>
    </div>
<mat-dialog-content>

    <br />
    <div class="title">
        <h3>Your team is {{team}}</h3>
    </div>
    <br />

    <div class="title">
        <h3>Members you can add:</h3>
    </div>

    <div *ngFor="let member of members">
        <mat-slide-toggle (click)="addMember(member)">
        </mat-slide-toggle>
        {{ member.firstname }} - {{member.lastname}} - {{member.status}}
    </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <button class="mat-raised-button button_cancel"
        (click)="closeDialog()">{{ "Buttons.Cancel" | translate }}</button>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <button class="mat-raised-button button_save"
       [disabled]="isEmpty()" (click)="saveMembers()">{{ "Buttons.Save" | translate }}</button>
</mat-dialog-actions>