<mat-icon (click)="closeDialog()" type="button" style="color: #009EE0; float: right;" aria-label="Cancel_icon">cancel
</mat-icon>
<ng-container>
  <div class="title">
    <h2 mat-dialog-title>{{ "Dashboards.User.UpdateRatings.Title" | translate }}</h2>
  </div>
  <br />
  <div class="title">
    <h3 mat-dialog-title>{{ "Dashboards.User.UpdateRatings.Title2" | translate }}</h3>
  </div>
  <mat-dialog-content>


    <!--Select Skills-->
    <mat-form-field appearance="fill">
      <mat-label>{{ "Entities.Abilities.Title" | translate }}</mat-label>
      <mat-select [formControl]="abilities" multiple >
        <mat-select-trigger>
          {{abilities.value ? abilities.value[0].skill_label + " - " + abilities.value[0].skill_category + " - " + abilities.value[0].skill_classification + " - Value: " + abilities.value[0].last_rating_value : ''}}
          <span *ngIf="abilities.value?.length > 1" class="example-additional-selection">
            (+{{abilities.value.length - 1}} {{abilities.value?.length === 2 ? 'other' : 'others'}})
          </span>
        </mat-select-trigger>
        <mat-option *ngFor="let Ability of Abilities" [value]="Ability">{{Ability.skill_label}} -
          {{Ability.skill_category}} -
          {{Ability.skill_classification}} - Value: {{Ability.last_rating_value}}
          <span class="material-icons" [ngStyle]="{'color':'#009EE0'}">
            star
          </span>
          <span class="material-icons" *ngIf="Ability.last_rating_value > 1" [ngStyle]="{'color':'#009EE0'}">
            star
          </span>
          <span class="material-icons" *ngIf="Ability.last_rating_value > 2" [ngStyle]="{'color':'#009EE0'}">
            star
          </span>
          <span class="material-icons" *ngIf="Ability.last_rating_value > 3" [ngStyle]="{'color':'#009EE0'}">
            star
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="title">
      <h3 mat-dialog-title>{{ "Dashboards.User.UpdateRatings.Title3" | translate }}</h3>
    </div>

    <div style="border-radius: 8px; background-color: #FFFFFF;">
      <div class="row">
        <div class="col-6 m-auto" style="cursor: default">
          <div>
            <span *ngIf="this.stars == 1" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
              star
            </span>
            <span *ngIf="this.stars == 1" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
              star_border
            </span>
            <span *ngIf="this.stars == 1" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
              star_border
            </span>
            <span *ngIf="this.stars == 1" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
              star_border
            </span>
            <span *ngIf="this.stars != 1" class="material-icons" [ngStyle]="{'color':'#666666'}">
              star
            </span>
            <span *ngIf="this.stars != 1" class="material-icons" [ngStyle]="{'color':'#666666'}">
              star_border
            </span>
            <span *ngIf="this.stars != 1" class="material-icons" [ngStyle]="{'color':'#666666'}">
              star_border
            </span>
            <span *ngIf="this.stars != 1" class="material-icons" [ngStyle]="{'color':'#666666'}">
              star_border
            </span>
            <span class="material-icons" [ngStyle]="{'color':'#009EE0'}" #tooltip="matTooltip"
              matTooltip='{{ "GetStarted.Standards.3" | translate }}'>
              info
            </span>
            <button class="buttoncustomclicked" (click)="setStarsClick(1)"
              *ngIf="this.stars == 1">{{ "Dashboards.StarRating.1" | translate }}</button>
            <button class="buttoncustom" (click)="setStarsClick(1)"
              *ngIf="this.stars != 1">{{ "Dashboards.StarRating.1" | translate }}</button>
          </div>
        </div>
        <div class="col-6 m-auto" style="cursor: default">
          <span *ngIf="this.stars == 2" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
            star
          </span>
          <span *ngIf="this.stars == 2" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
            star
          </span>
          <span *ngIf="this.stars == 2" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
            star_border
          </span>
          <span *ngIf="this.stars == 2" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
            star_border
          </span>
          <span *ngIf="this.stars != 2" class="material-icons" [ngStyle]="{'color':'#666666'}">
            star
          </span>
          <span *ngIf="this.stars != 2" class="material-icons" [ngStyle]="{'color':'#666666'}">
            star
          </span>
          <span *ngIf="this.stars != 2" class="material-icons" [ngStyle]="{'color':'#666666'}">
            star_border
          </span>
          <span *ngIf="this.stars != 2" class="material-icons" [ngStyle]="{'color':'#666666'}">
            star_border
          </span>
          <span class="material-icons" [ngStyle]="{'color':'#009EE0'}" #tooltip="matTooltip"
            matTooltip='{{ "GetStarted.Standards.4" | translate }}'>
            info
          </span>
          <button class="buttoncustomclicked" (click)="setStarsClick(2)"
            *ngIf="this.stars == 2">{{ "Dashboards.StarRating.2" | translate }}
          </button>
          <button class="buttoncustom" (click)="setStarsClick(2)"
            *ngIf="this.stars != 2">{{ "Dashboards.StarRating.2" | translate }}
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-6 m-auto" style="cursor: default">
          <span *ngIf="this.stars == 3" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
            star
          </span>
          <span *ngIf="this.stars == 3" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
            star
          </span>
          <span *ngIf="this.stars == 3" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
            star
          </span>
          <span *ngIf="this.stars == 3" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
            star_border
          </span>
          <span *ngIf="this.stars != 3" class="material-icons" [ngStyle]="{'color':'#666666'}">
            star
          </span>
          <span *ngIf="this.stars != 3" class="material-icons" [ngStyle]="{'color':'#666666'}">
            star
          </span>
          <span *ngIf="this.stars != 3" class="material-icons" [ngStyle]="{'color':'#666666'}">
            star
          </span>
          <span *ngIf="this.stars != 3" class="material-icons" [ngStyle]="{'color':'#666666'}">
            star_border
          </span>
          <span class="material-icons" [ngStyle]="{'color':'#009EE0'}" #tooltip="matTooltip"
            matTooltip='{{ "GetStarted.Standards.5" | translate }}'>
            info
          </span>
          <button class="buttoncustomclicked" (click)="setStarsClick(3)"
            *ngIf="this.stars == 3">{{ "Dashboards.StarRating.3" | translate }}</button>
          <button class="buttoncustom" (click)="setStarsClick(3)"
            *ngIf="this.stars != 3">{{ "Dashboards.StarRating.3" | translate }}</button>
        </div>
        <div class="col-6 m-auto" style="cursor: default">
          <span *ngIf="this.stars == 4" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
            star
          </span>
          <span *ngIf="this.stars == 4" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
            star
          </span>
          <span *ngIf="this.stars == 4" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
            star
          </span>
          <span *ngIf="this.stars == 4" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
            star
          </span>
          <span *ngIf="this.stars != 4" class="material-icons" [ngStyle]="{'color':'#666666'}">
            star
          </span>
          <span *ngIf="this.stars != 4" class="material-icons" [ngStyle]="{'color':'#666666'}">
            star
          </span>
          <span *ngIf="this.stars != 4" class="material-icons" [ngStyle]="{'color':'#666666'}">
            star
          </span>
          <span *ngIf="this.stars != 4" class="material-icons" [ngStyle]="{'color':'#666666'}">
            star
          </span>
          <span class="material-icons" [ngStyle]="{'color':'#009EE0'}" #tooltip="matTooltip"
            matTooltip='{{ "GetStarted.Standards.6" | translate }}'>
            info
          </span>
          <button class="buttoncustomclicked" (click)="setStarsClick(4)"
            *ngIf="this.stars == 4">{{ "Dashboards.StarRating.4" | translate }}</button>
          <button class="buttoncustom" (click)="setStarsClick(4)"
            *ngIf="this.stars != 4">{{ "Dashboards.StarRating.4" | translate }}</button>
        </div>
      </div>
    </div>
    <br>
    <div class="row rate-popup-info-label" *ngIf="this.stars !== 0">
      <label class="col-12 input-label" *ngIf="this.stars == 1">{{ "GetStarted.Standards.3" | translate }}</label>
      <label class="col-12 input-label" *ngIf="this.stars == 2">{{ "GetStarted.Standards.4" | translate }}</label>
      <label class="col-12 input-label" *ngIf="this.stars == 3">{{ "GetStarted.Standards.5" | translate }}</label>
      <label class="col-12 input-label" *ngIf="this.stars == 4">{{ "GetStarted.Standards.6" | translate }}</label>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button class="mat-raised-button button_cancel col-md-3"
      (click)="closeDialog()">{{ "Buttons.Cancel" | translate }}</button>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <button class="mat-raised-button button_save col-md-3" (click)="saveRating(abilities)"
      [disabled]="!choiceMade()">{{ "Buttons.Save" | translate }}</button>
  </mat-dialog-actions>