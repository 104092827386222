import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {map, tap} from 'rxjs/operators';

const baseUrl = environment.API_BASE_URL;
const httpOptions = {
   headers: new HttpHeaders({
     'Content-Type': 'application/json',
     Authorization: sessionStorage.getItem('authToken')
   }),
  body: {}
};

@Injectable({
  providedIn: 'root'
})
export class UsergroupsService {

  constructor(private http: HttpClient) { }

    getAllUsergroups(): Observable<any>{
      return this.http.get(`${baseUrl}user_group`, httpOptions);
    }

    createUserGroup(data): Observable<any>{
      return this.http.post(`${baseUrl}user_group`, data, httpOptions);
    }

    updateUserGroup(data): Observable<any>{
      httpOptions.body = data;
      return this.http.request<string>('put', `${baseUrl}user_group`, httpOptions).pipe(
        map((result: any) => result as string), // fix typescript typing
        tap((result: string) => {
            console.log('response from server:', result);
          }
        ));
    }

    listUserWithoutGroups(): Observable<any>{
      return this.http.get(`${baseUrl}user_group-list_users_without_group`, httpOptions);
    }

    listPotentialParents(usergroup): Observable<any>{
      return this.http.get(`${baseUrl}user_group-list_potential_parents?USER_GROUP_ID=${usergroup}`, httpOptions).pipe(
        map((result: any) => result as string), // fix typescript typing
        tap((result: string) => {
            console.log('response from server:', result);
          }
      ));
    }

    listUnnecessarySkills(usergroup,app): Observable<any>{
      return this.http.get(`${baseUrl}user_group-list_unnecessary_skills?USER_GROUP_ID=${usergroup}&APPLICATION_ID=${app}`, httpOptions).pipe(
        map((result: any) => result as string), // fix typescript typing
        tap((result: string) => {
            console.log('response from server:', result);
          }
      ));
    }

    addUserGroupNeed(data): Observable<any>{
      httpOptions.body = data;
      return this.http.request<string>('put', `${baseUrl}user_group-add_needs`, httpOptions).pipe(
        map((result: any) => result as string), // fix typescript typing
        tap((result: string) => {
            console.log('response from server:', result);
          }
        ));
    }

    deleteUserGroup(id): Observable<any>{
      httpOptions.body = {USER_GROUP_ID: id};
      return this.http.request<string>('delete', `${baseUrl}user_group`, httpOptions).pipe(
        map((result: any) => result as string), // fix typescript typing
        tap((result: string) => {
            console.log('response from server:', result);
          }
        ));
    }
}
