import {Component, ViewChild, OnInit, AfterViewInit} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Rating } from '../../../models/rating';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RatingsDialogComponent } from '../ratings-dialog/ratings-dialog.component';

/*
const RATINGS: Rating[] = [
  {
    creationDate: '01/01/2019',
    creationUser: 'antoine.blois',
    last_update_date: '10/12/2020',
    last_update_user: 'baptiste.roka',
    rating_value: 'Advanced',
    competence: 'Angular',
    user: 'antoine.blois'
  },
  {
    creationDate: '02/01/2019',
    creationUser: 'antoine.blois',
    last_update_date: '12/12/2020',
    last_update_user: 'baptiste.roka',
    rating_value: 'Beginner',
    competence: 'Angular',
    user: 'antoine.blois'
  },
  {
    creationDate: '03/01/2019',
    creationUser: 'antoine.blois',
    last_update_date: '19/12/2020',
    last_update_user: 'baptiste.roka',
    rating_value: 'Intermediate',
    competence: 'JS',
    user: 'antoine.blois'
  },
  {
    creationDate: '08/01/2019',
    creationUser: 'antoine.blois',
    last_update_date: '10/12/2020',
    last_update_user: 'baptiste.roka',
    rating_value: 'Expert',
    competence: 'Android',
    user: 'antoine.blois'
  },
  {
    creationDate: '10/01/2019',
    creationUser: 'antoine.blois',
    last_update_date: '10/12/2020',
    last_update_user: 'baptiste.roka',
    rating_value: 'Advanced',
    competence: 'Java',
    user: 'antoine.blois'
  },
  {
    creationDate: '15/01/2019',
    creationUser: 'antoine.blois',
    last_update_date: '10/12/2020',
    last_update_user: 'baptiste.roka',
    rating_value: 'Beginner',
    competence: 'NodeJS',
    user: 'antoine.blois'
  },
  {
    creationDate: '20/01/2019',
    creationUser: 'antoine.blois',
    last_update_date: '10/12/2020',
    last_update_user: 'baptiste.roka',
    rating_value: 'Intermediate',
    competence: 'AWS - API Gataway',
    user: 'antoine.blois'
  },
  {
    creationDate: '29/01/2019',
    creationUser: 'antoine.blois',
    last_update_date: '10/12/2020',
    last_update_user: 'baptiste.roka',
    rating_value: 'Expert',
    competence: 'Rancher',
    user: 'antoine.blois'
  },
  {
    creationDate: '04/01/2019',
    creationUser: 'antoine.blois',
    last_update_date: '10/08/2020',
    last_update_user: 'baptiste.roka',
    rating_value: 'Advanced',
    competence: 'SquashTM',
    user: 'antoine.blois'
  },
  {
    creationDate: '07/01/2019',
    creationUser: 'antoine.blois',
    last_update_date: '13/12/2020',
    last_update_user: 'baptiste.roka',
    rating_value: 'Beginner',
    competence: 'ReactJS',
    user: 'antoine.blois'
  },
  {
    creationDate: '11/01/2019',
    creationUser: 'antoine.blois',
    last_update_date: '10/11/2020',
    last_update_user: 'baptiste.roka',
    rating_value: 'Intermediate',
    competence: 'Terraform',
    user: 'antoine.blois'
  },
  {
    creationDate: '23/02/2019',
    creationUser: 'antoine.blois',
    last_update_date: '10/12/2020',
    last_update_user: 'baptiste.roka',
    rating_value: 'Expert',
    competence: 'TypeScript',
    user: 'antoine.blois'
  },
  {
    creationDate: '18/01/2019',
    creationUser: 'antoine.blois',
    last_update_date: '13/12/2020',
    last_update_user: 'baptiste.roka',
    rating_value: 'Advanced',
    competence: 'Kotlin',
    user: 'garry.verrier'
  }
];
*/
@Component({
  selector: 'app-ratings',
  templateUrl: './ratings.component.html',
  styleUrls: ['./ratings.component.css']
})
export class RatingsComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['creationDate', 'creationUser', 'last_update_date', 'last_update_user', 'rating_value', 'competence', 'user', 'operations'];
  dataSource: MatTableDataSource<Rating>;

  Ratings: Rating[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public dialog: MatDialog) {
    this.dataSource = new MatTableDataSource();
  }

  openDialogRatings() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    this.dialog.open(RatingsDialogComponent, dialogConfig);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit(): void {
  }

}
