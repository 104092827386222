<div class="grid-container">
  <div class="row">
    <button mat-raised-button class="col-md-2 button_create" color="primary" (click)="openDialog()">
      {{ "Dashboards.Buttons.AddUsergroupNeeds" | translate }}
    </button>
    <button mat-raised-button class="col-md-2 button_create" color="primary" (click)="openMembersDialog()">
      {{ "Dashboards.Buttons.AddMembers" | translate }}
    </button>
  </div>
  <mat-grid-list *ngIf="!production" cols="{{ ( cardLayout | async )?.columns }}" rowHeight="200px">


    <ng-container *ngFor="let usergroup of usergroups">
      <mat-grid-tile *ngFor="let i of [6]" [colspan]="( cardLayout | async )?.usergroups.cols"
        [rowspan]="( cardLayout | async )?.usergroups.rows">
        <app-dashboard-card title="{{usergroup.label}}" [displayMenu]="false">
          <button mat-raised-button class="col-md-2 button_create" color="primary" (click)="showData(usergroup, 'members')">View
            Members
          </button>
          <button mat-raised-button class="col-md-2 button_create" color="primary" (click)="showData(usergroup, 'applications')">View
            Applications
          </button>
        </app-dashboard-card>
      </mat-grid-tile>
    </ng-container>
    <mat-grid-tile *ngIf="showApps || showMembers" [colspan]="( cardLayout | async )?.details.cols" [rowspan]="( cardLayout | async )?.details.rows">
      <app-dashboard-card title="{{title}}" [displayMenu]="false">
        <app-dashboard-manager-details *ngIf="showMembers" [dataSource]="members"></app-dashboard-manager-details>
        <app-dashboard-manager-details *ngIf="showApps" [dataSource]="applications"></app-dashboard-manager-details>
      </app-dashboard-card>
    </mat-grid-tile>

    <!--Tree-->
    <!--    <mat-grid-tile [colspan]="( cardLayout | async )?.tree.cols" [rowspan]="( cardLayout | async )?.tree.rows">-->
    <!--      <app-dashboard-card title="Teams Tree" [displayMenu]="false">-->
    <!--      </app-dashboard-card>-->
    <!--    </mat-grid-tile>-->

    <!-- Charts -->
    <!--    <mat-grid-tile *ngFor="let i of [6]" [colspan]="( cardLayout | async )?.chart.cols"-->
    <!--                   [rowspan]="( cardLayout | async )?.chart.rows">-->
    <!--      <app-dashboard-card [displayMenu]="false">-->
    <!--      </app-dashboard-card>-->
    <!--    </mat-grid-tile>-->


    <!--Table-->


  </mat-grid-list>
</div>
