<br />

<div style="width: 90%; margin-left: 5%">
  <span style="font-size: 125%"><strong>{{ "GetStarted.Question" | translate }}</strong></span>
</div>

<br />

<div style="width: 90%; margin-left: 5%">
  <span style="text-decoration: underline; margin-top: 10%">{{ "GetStarted.Allows.Title" | translate }}</span>
  <p></p>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">{{ "GetStarted.Allows.1" | translate }}</li>
    <li class="list-group-item">{{ "GetStarted.Allows.2" | translate }}</li>
    <li class="list-group-item">{{ "GetStarted.Allows.3" | translate }}</li>
    <li class="list-group-item">{{ "GetStarted.Allows.4" | translate }}</li>
  </ul>
</div>

<br />

<div style="width: 90%; margin-left: 5%">
  <span style="text-decoration: underline; margin-top: 10%">{{ "GetStarted.Standards.Title" | translate }}</span>
  <p></p>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">{{ "GetStarted.Standards.1" | translate }}</li>
    <li class="list-group-item">{{ "GetStarted.Standards.2" | translate }}</li>
    <li class="list-group-item">{{ "GetStarted.Standards.3" | translate }}</li>
    <li class="list-group-item">{{ "GetStarted.Standards.4" | translate }}</li>
    <li class="list-group-item">{{ "GetStarted.Standards.5" | translate }}</li>
    <li class="list-group-item">{{ "GetStarted.Standards.6" | translate }}</li>
  </ul>
</div>
