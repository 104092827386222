import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public chartType: string = 'bar';
  public chartType2: string = 'horizontalBar';

  public chartDatasets: Array<any> = [
    { data: [35, 45, 50, 60, 75, 0, 100], label: 'You are an expert' }
  ];

  public chartLabels: Array<any> = ['None', 'Beginner', 'Intermediate', 'Advanced', 'Expert'];

  public chartColors: Array<any> = [
    {
      backgroundColor: [
        'rgba(230, 230, 230, 1)',
        'rgba(234, 250, 255, 1)',
        'rgba(204, 236, 249, 1)',
        'rgba(173, 234, 254, 1)',
        'rgba(0, 205, 255, 1)',
      ]
    }
  ];

  public chartOptions: any = {
    responsive: true
  };
  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }

  ngOnInit(): void {
  }

}
