import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabs-matrix-options',
  templateUrl: './tabs-matrix-options.component.html',
  styleUrls: ['./tabs-matrix-options.component.css']
})
export class TabsMatrixOptionsComponent implements OnInit {

  namePage: string[] = ['Import Matrix', 'Export Matrix', 'Team Matrix'];
  selectedPage = this.namePage[0];

  constructor() { }

  ngOnInit(): void {
  }

}
