import {Application} from '../../../models/application.model';
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {ApplicationsDialogComponent} from '../applications-dialog/applications-dialog.component';
import {ApplicationsService} from 'src/app/services/entities/applications.service';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {ApiService} from '../../../services/api.service';
import {MatSort, Sort} from '@angular/material/sort';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.css']
})
export class ApplicationsComponent implements OnInit, AfterViewInit {

  // COLUMNS
  displayedColumns: string[] = ['active', 'label', 'global_id', 'last_update_date', 'last_update_user', 'operations'];

  // PREPARE VARIABLES
  dataSource: MatTableDataSource<Application>;
  applications: Application[];
  application: Application = {
    label: '',
    global_id: '',
    active: true,
    last_update_user: ''
  };
  typeOfDialog = '';

  // SET PAGINATOR AND SORT
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // POSITION OF SNACKBAR
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog,
              private applicationsService: ApplicationsService,
              private snackBar: MatSnackBar,
              private apiService: ApiService) {
    this.apiService.callConnection().subscribe();
  }

  // DIALOG FOR CREATE OR UPDATE AN APPLICATION
  openDialog(application: Application, typeOfDialog): void {
    this.dialog.open(ApplicationsDialogComponent, {
      autoFocus: true, disableClose: false,
      data: {
        application: {
          APPLICATION_ID: application.APPLICATION_ID,
          label: application.label,
          global_id: application.global_id,
          active: application.active
        },
        typeOfDialog
      }
    }).afterClosed().subscribe(() => {
      this.retrieveAllApplications();
    });
  }

  // FILTER FOR TABLE
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit(): void {
    //this.retrieveAllApplications();
  }

  ngAfterViewInit() {
    const sortState: Sort = { active: 'last_update_date', direction: 'desc' };
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
    this.retrieveAllApplications();
  }

  // GET ALL APPLICATIONS FROM DATABASE
  retrieveAllApplications(): void {
    this.applicationsService.getAllApplications()
      .subscribe(
        data => {
          this.applications = data;
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        },
        error => {
          console.log(error);
        });
  }

  setUse(event: MatSlideToggleChange, application: Application) {
    application.active = event.checked;
    this.applicationsService.updateApplication(application)
      .subscribe(
        data => {
          this.snackBar.open('An application has been modified', 'OK', {
            duration: 4000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['snackbar-success']
          });
          this.retrieveAllApplications();
        },
        error => {
          this.snackBar.open('ERROR for modify an application', 'OK', {
            duration: 4000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['snackbar-error']
          });
        });
  }
}
