<div style="display: block">
    <canvas mdbChart
        [chartType]="chartType"
        [datasets]="chartDatasets"
        [labels]="chartLabels"
        [colors]="lineChartColors"
        [options]="chartOptions"
        [legend]="true"
        (chartHover)="chartHovered($event)"
        (chartClick)="chartClicked($event)">
    </canvas>
  </div>
