import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { DashboardUserUpdateRatingDialogComponent } from '../dashboard-user-update-rating-dialog/dashboard-user-update-rating-dialog.component';
import { DashboardUserAbilitiesTableDisableDialogComponent } from '../dashboard-user-abilities-table-disable-dialog/dashboard-user-abilities-table-disable-dialog.component';
import {Ability, SummarizedAbility} from '../../../models/ability.model';
import { UserService } from 'src/app/services/entities/user.service';
import {Subscription} from 'rxjs';
import {SharedService} from '../../../services/shared.service';

@Component({
  selector: 'app-dashboard-user-abilities-table',
  templateUrl: './dashboard-user-abilities-table.component.html',
  styleUrls: ['./dashboard-user-abilities-table.component.css']
})
export class DashboardUserAbilitiesTableComponent implements OnInit, AfterViewInit {
  callRetrieveAbilities: Subscription;
  abilities: Ability[] = [];
  mobile = false;

  constructor(public dialog: MatDialog,
              private usersService: UserService,
              private snackBar: MatSnackBar,
              private sharedService: SharedService) {
    this.dataSource = new MatTableDataSource(this.abilities);
    this.callRetrieveAbilities = this.sharedService.getTheCallFunctionEvent().subscribe(() => {
      this.retrieveUserAbilities();
    });
  }

  displayedColumns: string[] = ['skill_name', 'skill_type', 'skill_category', 'last_rating_date', 'last_rating_value', 'operations'];
  dataSource: MatTableDataSource<Ability>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // POSITION OF SNACKBAR
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  openDisableDialog(ability: SummarizedAbility, value: number){
    this.dialog.open(DashboardUserAbilitiesTableDisableDialogComponent, {
      autoFocus: true, disableClose: false,
      data: {
        ability: {
          ABILITY_ID: ability.ABILITY_ID,
          last_rating_date: ability.last_rating_date,
          last_rating_value: ability.last_rating_value,
          skill_category: ability.skill_category,
          skill_classification: ability.skill_classification,
          skill_label: ability.skill_label
        },
        newValue: value
      }
    }).afterClosed().subscribe(() => {
      this.retrieveUserAbilities();
    });
  }

  openDialog(ability: SummarizedAbility, value: number): void {
    this.dialog.open(DashboardUserUpdateRatingDialogComponent, {
      autoFocus: true, disableClose: false,
      data: {
        ability: {
          ABILITY_ID: ability.ABILITY_ID,
          last_rating_date: ability.last_rating_date,
          last_rating_value: ability.last_rating_value,
          skill_category: ability.skill_category,
          skill_classification: ability.skill_classification,
          skill_label: ability.skill_label
        },
        newValue: value
      }
    }).afterClosed().subscribe(() => {
      this.retrieveUserAbilities();
    });
  }

  retrieveUserAbilities(): void {
    console.log('retrieveAbilities');
    this.usersService.getUserLastAbilities()
      .subscribe(
        data => {
          this.abilities = data;
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        },
        error => {
          console.log(error);
        });
  }

  ngAfterViewInit() {
    const sortState: Sort = { active: 'last_rating_date', direction: 'desc' };
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
    this.retrieveUserAbilities();
  }

  numberOfDaysSince(dateSent) {
    const currentDate = new Date();
    dateSent = new Date(dateSent);
    return Math.floor((
      Date.UTC(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      )
      - Date.UTC(dateSent.getFullYear(),
      dateSent.getMonth(),
      dateSent.getDate()) ) / (1000 * 60 * 60 * 24)
    );
  }

  attributeColor(date) {
    const days = this.numberOfDaysSince(date);
    if (days > 90) { return 'warn-3'; }
    else if (days > 60) { return 'warn-2'; }
    else if (days > 30) { return 'warn-1'; }
    else { return 'warn-0'; }
  }

  ngOnInit(): void {
    this.retrieveUserAbilities();
    if (window.screen.width <= 790) { // 768px portrait
      this.mobile = true;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
