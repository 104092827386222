<br>
<div class="col-12">
  <div class="row">
    &nbsp;&nbsp;
    <button mat-raised-button class="col-md-4 button_create" class="buttoncreate" color="primary" (click)="openDialog({}, 'create')">{{ "Entities.Applications.CreateButton" | translate }}</button>
    <mat-form-field class="col-md-7 filter">
      <mat-label >{{ "Entities.Global.Filter.Title" | translate }}</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="{{ 'Entities.Global.Filter.Text' | translate }}" #input>
      <mat-icon aria-label="Search_icon">search</mat-icon>
    </mat-form-field>
  </div>
  <br>
  <br>
  <div class="col-12 data-table">
    <table mat-table matSort matTableResponsive [dataSource]="dataSource" matSort>

      <!-- Active Column -->
      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "Entities.Global.Fields.Active" | translate }}</th>
        <td mat-cell *matCellDef="let app">
          <mat-slide-toggle [checked]="app.active" (change)="setUse($event,app)"></mat-slide-toggle>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="label">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "Entities.Global.Fields.Name" | translate }}</th>
        <td mat-cell *matCellDef="let app"> {{app.label}} </td>
      </ng-container>

      <!-- Key Column -->
      <ng-container matColumnDef="global_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "Entities.Applications.Fields.Key" | translate }}</th>
        <td mat-cell *matCellDef="let app"> {{app.global_id}} </td>
      </ng-container>

      <!-- Last Update Date Column -->
      <ng-container matColumnDef="last_update_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "Entities.Global.Fields.LastUpdateDate" | translate }}</th>
        <td mat-cell *matCellDef="let app">
          {{app.last_update_date.split('T')[0]}}
        </td>
      </ng-container>

      <!-- Last Update User Column -->
      <ng-container matColumnDef="last_update_user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "Entities.Global.Fields.LastUpdateUser" | translate }}</th>
        <td mat-cell *matCellDef="let app"> {{app.last_update_user.split('@')[0]}} </td>
      </ng-container>

      <!-- Buttons Column -->
      <ng-container matColumnDef="operations">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let app">
          <button class="button_edit" mat-icon-button (click)="openDialog(app,'edit')">{{ "Entities.Global.Fields.Operations.Edit" | translate }}</button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let app; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">{{ "Entities.Global.Filter.NoMatch" | translate }} "{{input.value}}"</td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" style="display: flex; justify-content: center"></mat-paginator>
  </div>

</div>


