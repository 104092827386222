import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {map, tap} from 'rxjs/operators';

const baseUrl = environment.API_BASE_URL;
const httpOptions = {
   headers: new HttpHeaders({
     'Content-Type': 'application/json',
     Authorization: sessionStorage.getItem('authToken')
   }),
  body: {}
};

@Injectable({
  providedIn: 'root'
})
export class RatingsService {

  constructor(private http: HttpClient) { }

    getAllRatings(): Observable<any>{
      return this.http.get(`${baseUrl}users`, httpOptions);
    }

    createRating(data): Observable<any>{
      return this.http.post(`${baseUrl}user`, data, httpOptions);
    }

    updateRating(data): Observable<any>{
      httpOptions.body = data;
      return this.http.request<string>('put',`${baseUrl}user`, httpOptions).pipe(
        map((result:any) => result as string),
        tap((result:string) => {
          console.log('response from server:', result);
        }
      ));
    }

    deleteRating(id): Observable<any>{
      httpOptions.body = {RATING_ID: id};
      return this.http.request<string>('delete', `${baseUrl}user`, httpOptions).pipe(
        map((result: any) => result as string), // fix typescript typing
        tap((result: string) => {
            console.log('response from server:', result);
          }
        ));
    }
}
