<mat-icon (click)="closeDialog()" type="button" style="color: #009EE0; float: right;" aria-label="Cancel_icon">cancel</mat-icon>
<ng-container>
  <div class="title">
    <h2 mat-dialog-title *ngIf="typeOfDialog === 'create'">{{ "Entities.Applications.Dialog.CreateTitle" | translate }}</h2>
    <h2 mat-dialog-title *ngIf="typeOfDialog === 'edit'">{{ "Entities.Applications.Dialog.EditTitle" | translate }}</h2>
  </div>
  <br />
  <mat-dialog-content>
    <div class="row">

      <!--Name-->
      <div class="col-md-4 m-auto">
        <label class="input-label" >{{ 'Entities.Global.Fields.Name' | translate }}</label>
        <br>
        <input type="text" class="input-text" [(ngModel)]="application.label" id="label">
      </div>

      <!--Key-->
      <div class="col-md-4 m-auto">
        <label class="input-label" >{{ 'Entities.Applications.Fields.Key' | translate }}</label>
        <br>
        <input type="text" class="input-text" [(ngModel)]="application.global_id" id="global_id">
      </div>

      <!--Active-->
      <div class="col-md-4 m-auto">
        <label class="input-label" >{{ 'Entities.Global.Fields.Active' | translate }}</label>
        <br>
        <mat-slide-toggle [(ngModel)]="application.active" [checked]="application.active"></mat-slide-toggle>
      </div>
    </div>

  </mat-dialog-content>
</ng-container>
<mat-dialog-actions align="center">
  <button class="mat-raised-button button_cancel col-md-3" (click)="closeDialog()">{{ "Buttons.Cancel" | translate }}</button>
  &nbsp;&nbsp;&nbsp;&nbsp;
  <button class="mat-raised-button button_save col-md-3" (click)="saveData()" [disabled]="!fieldsAreFilled()">{{ "Buttons.Save" | translate }}</button>
</mat-dialog-actions>
