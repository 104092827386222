import {Component, ViewEncapsulation} from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import {ApiService} from '../../../services/api.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-dashboard-user',
  templateUrl: './dashboard-user.component.html',
  styleUrls: ['./dashboard-user.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardUserComponent {
  cardLayout = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return {
          columns: 1,
          miniCard: { cols: 1, rows: 1 },
          chart: { cols: 1, rows: 3 },
          table: { cols: 1, rows: 5 },
        };
      }

      return {
        columns: 4,
        miniCard: { cols: 1, rows: 1 },
        chart: { cols: 2, rows: 3 },
        table: { cols: 4, rows: 4 },
      };
    })
  );
  production = false;
  constructor(private breakpointObserver: BreakpointObserver,
              private apiService: ApiService) {
    this.apiService.callConnection().subscribe();
    this.production = environment.production;
  }
}
