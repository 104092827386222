import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-matrix-usergroups',
  templateUrl: './matrix-usergroups.component.html',
  styleUrls: ['./matrix-usergroups.component.css']
})
export class MatrixUsergroupsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
