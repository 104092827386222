import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import {SummarizedUserAbility} from '../../models/user-ability.model';
import { UserService } from 'src/app/services/entities/user.service';
import {Subscription} from 'rxjs';
import {SharedService} from '../../services/shared.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit, AfterViewInit {
  callRetrieveAbilities: Subscription;
  abilities: SummarizedUserAbility[] = [];
  mobile = false;

  constructor(public dialog: MatDialog,
              private usersService: UserService,
              private snackBar: MatSnackBar,
              private sharedService: SharedService) {
    this.dataSource = new MatTableDataSource(this.abilities);
    this.callRetrieveAbilities = this.sharedService.getTheCallFunctionEvent().subscribe(() => {
      this.retrieveUserAbilities();
    });
  }

  displayedColumns: string[] = ['username','skill_name', 'skill_type', 'skill_category', 'last_rating_value'];
  dataSource: MatTableDataSource<SummarizedUserAbility>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // POSITION OF SNACKBAR
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  retrieveUserAbilities(): void {
    console.log('retrieveAbilities');
    this.usersService.getAllUsersAbilities()
      .subscribe(
        data => {
          this.abilities = data;
          console.log(data);
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        },
        error => {
          console.log(error);
        });
  }

  ngAfterViewInit() {
    const sortState: Sort = { active: 'label', direction: 'desc' };
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
    this.retrieveUserAbilities();
  }

  ngOnInit(): void {
    this.retrieveUserAbilities();
    if (window.screen.width <= 790) { // 768px portrait
      this.mobile = true;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
