import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/entities/user.service';
import {ApiService} from '../../services/api.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  firstName = '';
  lastName = '';
  email = '';
  translation_language = '';
  work_time = '';
  status = '';
  role = '';

  profile = {
    work_time: "",
    translation_language: ""
  }

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private apiService: ApiService,
              private userService:UserService,
              private snackBar: MatSnackBar) {                
                this.userService.getProfile().subscribe(
                  (response:any) => {
                    this.firstName = response.first_name;
                    this.lastName = response.last_name;
                    this.email = response.email;
                    this.translation_language = response.translation_language;
                    this.work_time = response.work_time;
                    this.status = response.status;
                    this.role = response.job;
                    this.profile.translation_language = response.translation_language,
                    this.profile.work_time = response.work_time
                  }
                );
  }

  ngOnInit(): void {
    this.profile.translation_language = this.translation_language;
    this.profile.work_time = this.work_time;
  }


  updateProfile() {

    const data = {
      work_time: this.profile.work_time,
      translation_language: this.profile.translation_language
    }

    console.log(data);

    this.userService.updateProfile(data)
        .subscribe(
          (res: HttpResponse<User>) => {
            this.snackBar.open('Your profile has been modified', 'OK', {
              duration: 4000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['snackbar-success']
            });
          },
          (error: HttpErrorResponse) => {
            this.snackBar.open('ERROR when trying to modify your profile', 'OK', {
              duration: 4000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['snackbar-error']
            });
          }
        );
    }

  }


