import { Component, OnInit } from '@angular/core';
import {SkillsDialogComponent} from "../../entities/skills-dialog/skills-dialog.component";
import {DashboardUserAddAbilitiesDialogComponent} from "../dashboard-user-add-abilities-dialog/dashboard-user-add-abilities-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import { DashboardUserUpdateRatingDialogComponent } from '../dashboard-user-update-rating-dialog/dashboard-user-update-rating-dialog.component';
import { DashboardUserUpdateRatingsDialogComponent } from '../dashboard-user-update-ratings-dialog/dashboard-user-update-ratings-dialog.component';

@Component({
  selector: 'app-dashboard-user-buttons',
  templateUrl: './dashboard-user-buttons.component.html',
  styleUrls: ['./dashboard-user-buttons.component.css']
})
export class DashboardUserButtonsComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openDialog(): void {
    this.dialog.open(DashboardUserAddAbilitiesDialogComponent, {
      // height: '522px',
      // width: '496px',
      autoFocus: true,
      disableClose: false,
      data: {}
    });
  }

  openUpdateDialog(): void {
    this.dialog.open(DashboardUserUpdateRatingsDialogComponent, {
      width: '40%',
      autoFocus: false,
      disableClose: false,
      data: {}
    });
  }

}
