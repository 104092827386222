// MODULES
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {AppRoutingModule} from './app-routing.module';
import {ChartsModule} from 'ng2-charts';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import {CommonModule} from '@angular/common';
import {AlertsModule} from 'angular-alert-module';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
// COMPONENTS
import {AppComponent} from './app.component';
import {GetStartedComponent} from './components/get-started/get-started.component';
import {HelpComponent} from './components/help/help.component';
import {ProfileComponent} from './components/profile/profile.component';
import {MyMatrixComponent} from './components/my-matrix/my-matrix.component';
import {DashboardComponent} from './components/dashboards/dashboard.component';
import {ApplicationsComponent} from './components/entities/applications/applications.component';
import {SkillsComponent} from './components/entities/skills/skills.component';
import {RatingsComponent} from './components/entities/ratings/ratings.component';
import {UsergroupsComponent} from './components/entities/usergroups/usergroups.component';
import {ImportMatrixComponent} from './components/matrix-options/import-matrix/import-matrix.component';
import {ExportMatrixComponent} from './components/matrix-options/export-matrix/export-matrix.component';
import {MatrixUsergroupsComponent} from './components/matrix-options/matrix-usergroups/matrix-usergroups.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NavbarMenuComponent} from './components/navs/navbar-menu/navbar-menu.component';
import {TabsEntitiesComponent} from './components/navs/tabs-entities/tabs-entities.component';
import {TabsMatrixOptionsComponent} from './components/navs/tabs-matrix-options/tabs-matrix-options.component';
import {TabsDashboardsComponent} from './components/navs/tabs-dashboards/tabs-dashboards.component';
import {ApplicationsDialogComponent} from './components/entities/applications-dialog/applications-dialog.component';
import {UsergroupsDialogComponent} from './components/entities/usergroups-dialog/usergroups-dialog.component';
import {SkillsDialogComponent} from './components/entities/skills-dialog/skills-dialog.component';
import {RatingsDialogComponent} from './components/entities/ratings-dialog/ratings-dialog.component';
import {MatTableResponsiveModule} from './components/mat-table-responsive/mat-table-responsive.module';
import {DashboardUserAbilitiesTableComponent} from './components/dashboards/dashboard-user-abilities-table/dashboard-user-abilities-table.component';
import {DashboardCardComponent} from './components/dashboards/dashboard-card/dashboard-card.component';
import {StarRatingComponent} from './components/dashboards/star-rating/star-rating.component';
import {DashboardUserAddAbilitiesDialogComponent} from './components/dashboards/dashboard-user-add-abilities-dialog/dashboard-user-add-abilities-dialog.component';
import {DashboardUserUpdateRatingDialogComponent} from './components/dashboards/dashboard-user-update-rating-dialog/dashboard-user-update-rating-dialog.component';
import {DashboardUserButtonsComponent} from './components/dashboards/dashboard-user-buttons/dashboard-user-buttons.component';
import {DashboardUserAbilitiesEvolutionChartComponent} from './components/dashboards/dashboard-user-abilities-evolution-chart/dashboard-user-abilities-evolution-chart.component';
import {DashboardUserComponent} from './components/dashboards/dashboard-user/dashboard-user.component';
import {DashboardUsergroupsComponent} from './components/dashboards/dashboard-usergroups/dashboard-usergroups.component';
import {SearchComponent} from './components/search/search.component';
// ANGULAR MATERIAL
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {LayoutModule} from '@angular/cdk/layout';
// TRANSLATE
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { StarRatingNewComponent } from './components/dashboards/star-rating-new/star-rating-new.component';
import { DashboardManagerDetailsComponent } from './components/dashboards/dashboard-manager-details/dashboard-manager-details.component';
import { DashboardTeamsButtonsComponent } from './components/dashboards/dashboard-teams-buttons/dashboard-teams-buttons.component';
import { DashboardTeamsAddNeedsDialogComponent } from './components/dashboards/dashboard-teams-add-needs-dialog/dashboard-teams-add-needs-dialog.component';
import { DashboardUserUpdateRatingsDialogComponent } from './components/dashboards/dashboard-user-update-ratings-dialog/dashboard-user-update-ratings-dialog.component';
import { DashboardUserDistributionChartComponent } from './components/dashboards/dashboard-user-distribution-chart/dashboard-user-distribution-chart.component';
import { DashboardUserAbilitiesTableDisableDialogComponent } from './components/dashboards/dashboard-user-abilities-table-disable-dialog/dashboard-user-abilities-table-disable-dialog.component';
import { DashboardUserAddSkillDialogComponent } from './components/dashboards/dashboard-user-add-skill-dialog/dashboard-user-add-skill-dialog.component';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import {LoadingScreenInterceptor} from './services/loading-screen/loading.interceptor';
import { DashboardTeamsAddMembersDialogComponent } from './components/dashboards/dashboard-teams-add-members-dialog/dashboard-teams-add-members-dialog.component';

// REQUIRED FOR AOT COMPILATION
export function HttpLoaderFactory(http: HttpClient){
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    GetStartedComponent,
    HelpComponent,
    ProfileComponent,
    MyMatrixComponent,
    DashboardComponent,
    ApplicationsComponent,
    SkillsComponent,
    RatingsComponent,
    UsergroupsComponent,
    ImportMatrixComponent,
    ExportMatrixComponent,
    MatrixUsergroupsComponent,
    NotFoundComponent,
    NavbarMenuComponent,
    TabsEntitiesComponent,
    TabsMatrixOptionsComponent,
    ApplicationsDialogComponent,
    UsergroupsDialogComponent,
    SkillsDialogComponent,
    RatingsDialogComponent,
    TabsDashboardsComponent,
    DashboardUserComponent,
    DashboardUsergroupsComponent,
    DashboardUserAbilitiesTableComponent,
    DashboardCardComponent,
    StarRatingComponent,
    DashboardUserAddAbilitiesDialogComponent,
    DashboardUserUpdateRatingDialogComponent,
    DashboardUserButtonsComponent,
    DashboardUserAbilitiesEvolutionChartComponent,
    StarRatingNewComponent,
    DashboardManagerDetailsComponent,
    DashboardTeamsButtonsComponent,
    DashboardTeamsAddNeedsDialogComponent,
    DashboardUserUpdateRatingsDialogComponent,
    DashboardUserDistributionChartComponent,
    DashboardUserAbilitiesTableDisableDialogComponent,
    DashboardUserAddSkillDialogComponent,
    LoadingScreenComponent,
    DashboardTeamsAddMembersDialogComponent,
    SearchComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MDBBootstrapModule.forRoot(),
    BrowserAnimationsModule,
    ChartsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    AlertsModule,
    HttpClientModule,
    MatTableResponsiveModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LayoutModule,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   // Register the ServiceWorker as soon as the app is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
export class ViewModule {

}
