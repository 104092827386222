import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { UserService } from 'src/app/services/entities/user.service';

@Component({
  selector: 'app-navbar-menu',
  templateUrl: './navbar-menu.component.html',
  styleUrls: ['./navbar-menu.component.css']
})
export class NavbarMenuComponent implements OnInit {

  navbarOpen = false;
  selectedLanguage = 'en';
  job = '';
  navTeamLead = true;
  showPromptToInstall = false;
  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  constructor(public translate: TranslateService, private userService: UserService) {
    translate.addLangs(['en', 'fr', 'de']);
    translate.setDefaultLang('en');

    this.userService.getProfile().subscribe(
      (response: any) => {
        this.job = response.job;
      }
    );

    this.navTeamLead = this.job !== 'user';
  }



  useLanguage(language: string) {
    this.translate.use(language);
  }

  getSelectedLanguage() {
    this.selectedLanguage = this.translate.currentLang;
  }

  ngOnInit(): void {

  }
}
