import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UsergroupsService } from '../../../services/entities/usergroups.service';
import { MatDialog } from '@angular/material/dialog';
import { UsergroupsDialogComponent } from '../usergroups-dialog/usergroups-dialog.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Usergroup } from '../../../models/usergroup.model';
import { ApiService } from '../../../services/api.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { isEmpty } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-usergroups',
  templateUrl: './usergroups.component.html',
  styleUrls: ['./usergroups.component.css']
})
export class UsergroupsComponent implements OnInit, AfterViewInit {

  // COLUMNS
  displayedColumns: string[] = ['active', 'label', 'last_update_date', 'last_update_user', 'manager', 'parent' , 'operations'];

  // PREPARE VARIABLES
  dataSource: MatTableDataSource<Usergroup>;
  usergroups: Usergroup[];

  manager: User = {
    USER_ID: ''
  };

  usergroup: Usergroup = {
    USER_GROUP_ID: '',
    label: '',
    active: true,
    manager: this.manager,
    parent: {
      USER_GROUP_ID: '',
      label: ''
    }
  };
  noparent: Usergroup = {
    USER_GROUP_ID: '',
    label: '--No parent--'
  };

  typeOfDialog = '';

  // SET PAGINATOR AND SORT
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // POSITION OF SNACKBAR
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private userGroupsService: UsergroupsService, private snackBar: MatSnackBar, private apiService: ApiService) {
    this.apiService.callConnection().subscribe();
  }

  // DIALOG FOR CREATE OR UPDATE A USERGROUP
  openDialog(usergroup, typeOfDialog): void {

    if (usergroup.manager === undefined) {
      usergroup.manager = '';
    }

    if (usergroup.parent === undefined) {
      usergroup.parent = '';
    }

    this.dialog.open(UsergroupsDialogComponent, {
      autoFocus: true, disableClose: false,
      data: {
        usergroup: {
          USER_GROUP_ID: usergroup.USER_GROUP_ID,
          label: usergroup.label,
          active: usergroup.active,
          manager: usergroup.manager,
          parent: usergroup.parent
        },
        typeOfDialog
      }
    }).afterClosed().subscribe(() => {
      this.retrieveAllUsergroups();
    });
  }

  // FILTER FOR TABLE
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit(): void {
    //this.retrieveAllUsergroups();
  }

  ngAfterViewInit() {
    const sortState: Sort = { active: 'last_update_date', direction: 'desc' };
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
    this.retrieveAllUsergroups();
  }

  // GET ALL USERGROUPS FROM DATABASE
  retrieveAllUsergroups(): void {
    this.userGroupsService.getAllUsergroups()
      .subscribe(
        data => {
          this.usergroups = data;
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        },
        error => {
          console.log(error);
        });
  }

  setUse(event: MatSlideToggleChange, usergroup: Usergroup) {
    usergroup.active = event.checked;
    this.userGroupsService.updateUserGroup(usergroup)
      .subscribe(
        data => {
          this.snackBar.open('A usergroup has been modified', 'OK', {
            duration: 4000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['snackbar-success']
          });
          this.retrieveAllUsergroups();
        },
        error => {
          this.snackBar.open('ERROR for modify a usergroup', 'OK', {
            duration: 4000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['snackbar-error']
          });
        });
  }
}
