<br/>

<div class="col-md-12">
  <div>
    <input type="button" class="btn col-md-2"
           style="color: white; border-radius: 4px; background-color: #009EE0; box-shadow: 0 2px 4px 0 rgba(0,0,0,0.14)"
           (click)="openDialogRatings()" value="Create Rating">
    &nbsp;&nbsp;
    <mat-form-field class="col-md-9">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Filter..." #input>
    </mat-form-field>
  </div>

  <br/>
  <br/>

  <div class="mat-elevation-z8 col-md-12">
    <table mat-table matSort matTableResponsive [dataSource]="dataSource" matSort>

      <!-- Creation Date Column -->
      <ng-container matColumnDef="creationDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: black"> CREATION DATE</th>
        <td mat-cell *matCellDef="let rate"> {{rate.creationDate}} </td>
      </ng-container>

      <!-- Creation User Column -->
      <ng-container matColumnDef="creationUser">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: black"> CREATION USER</th>
        <td mat-cell *matCellDef="let rate"> {{rate.creationUser}} </td>
      </ng-container>

      <!-- Last Update Date Column -->
      <ng-container matColumnDef="last_update_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: black"> UPDATE ON</th>
        <td mat-cell *matCellDef="let rate"> {{rate.last_update_date}} </td>
      </ng-container>

      <!-- Last Update User Column -->
      <ng-container matColumnDef="last_update_user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: black"> UPDATE BY</th>
        <td mat-cell *matCellDef="let rate"> {{rate.last_update_user}} </td>
      </ng-container>

      <!-- Rate Value Column -->
      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: black"> RATE VALUE</th>
        <td mat-cell *matCellDef="let rate"> {{rate.value}} </td>
      </ng-container>

      <!-- Competence Column -->
      <ng-container matColumnDef="competence">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: black"> SKILL</th>
        <td mat-cell *matCellDef="let rate"> {{rate.competence}} </td>
      </ng-container>

      <!-- User Column -->
      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: black"> USER</th>
        <td mat-cell *matCellDef="let rate"> {{rate.user}} </td>
      </ng-container>

      <!-- Buttons Column -->
      <ng-container matColumnDef="operations">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let app" style="color: #009EE0; text-align: center"><span style="margin-right: 5%">VIEW</span><span
          style="margin-right: 5%">EDIT</span>
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash-fill" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"/>
          </svg>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let app; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" style="display: flex; justify-content: center"></mat-paginator>
  </div>

</div>



