import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { DashboardUserUpdateRatingDialogComponent } from '../dashboard-user-update-rating-dialog/dashboard-user-update-rating-dialog.component';
import { DashboardTeamsAddNeedsDialogComponent } from '../dashboard-teams-add-needs-dialog/dashboard-teams-add-needs-dialog.component';
import { DashboardTeamsAddMembersDialogComponent } from '../dashboard-teams-add-members-dialog/dashboard-teams-add-members-dialog.component';

@Component({
  selector: 'app-dashboard-teams-buttons',
  templateUrl: './dashboard-teams-buttons.component.html',
  styleUrls: ['./dashboard-teams-buttons.component.css']
})
export class DashboardTeamsButtonsComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openDialog(): void {
    this.dialog.open(DashboardTeamsAddNeedsDialogComponent, {
      autoFocus: true,
      disableClose: true,
      data: {}
    });
  }

  openMembersDialog(): void {
    this.dialog.open(DashboardTeamsAddMembersDialogComponent, {
      autoFocus: true,
      disableClose: true,
      data: {}
    });
  }

}
