import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/entities/user.service';

@Component({
  selector: 'app-tabs-dashboards',
  templateUrl: './tabs-dashboards.component.html',
  styleUrls: ['./tabs-dashboards.component.css']
})
export class TabsDashboardsComponent implements OnInit {


  job = '';
  navTeamLead = true;

  constructor(private userService: UserService) {
    this.userService.getProfile().subscribe(
      (response: any) => {
        this.job = response.job;
      }
    );

    this.navTeamLead = this.job !== 'user';
  }

  ngOnInit(): void {
  }

}
