import { Component} from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-dashboard-user-distribution-chart',
  templateUrl: './dashboard-user-distribution-chart.component.html',
  styleUrls: ['./dashboard-user-distribution-chart.component.css']
})
export class DashboardUserDistributionChartComponent {

  constructor() { }
  public chartType: string = 'bar';

  public chartDatasets: Array<any> = [
    { data: [65, 59, 80, 81, 0, 100], label: 'Current Rating' }
  ];

  public chartLabels: Array<any> = ['Beginner', 'Intermediate', 'Advanced', 'Expert'];

  public lineChartColors: Color[] = [
    {
      backgroundColor: 'rgba(0,158,224,0.3)',
    },
  ];

  public chartOptions: any = {
    responsive: true
  };
  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }

}
