<br />

<div style="text-align: center; font-size: 150%">
  <strong>{{ "MatrixOptions.MatrixImport.Header" | translate }}</strong>
</div>

<br />
<br />

<div style="text-align: center">
  <p>{{ "MatrixOptions.MatrixImport.Text" | translate }}</p>

  <br />
  <br />

  <div class="col-12">
    <input type="button" class="btn btn-success container" value="{{ 'MatrixOptions.MatrixImport.UploadButton' | translate }}">
  </div>

  <br />

  <img src="assets/img/Import_Matrix.png" class="img-fluid">
</div>
