import {Component} from '@angular/core';
import {map} from 'rxjs/operators';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {ApiService} from '../../../services/api.service';
import {DashboardTeamsAddNeedsDialogComponent} from '../dashboard-teams-add-needs-dialog/dashboard-teams-add-needs-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Usergroup} from '../../../models/usergroup.model';
import { DashboardTeamsAddMembersDialogComponent } from '../dashboard-teams-add-members-dialog/dashboard-teams-add-members-dialog.component';
import {User} from '../../../models/user.model';
import {Application} from '../../../models/application.model';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-dashboard-usergroups',
  templateUrl: './dashboard-usergroups.component.html',
  styleUrls: ['./dashboard-usergroups.component.css']
})
export class DashboardUsergroupsComponent {

  cardLayout = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({matches}) => {
      if (matches) {
        return {
          columns: 1,
          usergroups: {cols: 1, rows: 2},
          details: {cols: 1, rows: 4}
          // miniCard: {cols: 1, rows: 1},

          // table: {cols: 1, rows: 4},
          // tree: { cols: 1, rows: 1 },
        };
      }

      return {
        columns: 6,
        usergroups: {cols: 1, rows: 1},
        details: {cols: 2, rows: 3}
        // miniCard: { cols: 1, rows: 1 },
        // table: { cols: 4, rows: 4 },
        // tree: { cols: 2, rows: 4 },
      };
    })
  );

  usergroups: Usergroup[] = [
    {
      label: 'ITE-BM',
      members: [
        {
          USER_ID: 'Garry verrier'
        },
        {
          USER_ID: 'Tristan michel'
        }
      ],
      applications: [
        {
          label: 'SkillMatrix'
        },
        {
          label: 'DLS'
        },
        {
          label: 'Threading Performance'
        },
        {
          label: 'VUP'
        },
      ]
    },
    {
      label: 'ITE-BMDF',
      members: [
        {
          USER_ID: 'Garry verrier'
        },
        {
          USER_ID: 'Tristan michel'
        }
        ,
        {
          USER_ID: 'Tristan michel'
        },
        {
          USER_ID: 'Tristan michel'
        }
      ]
    },
    {
      label: 'ITE-BMDG',
      members: [
        {
          USER_ID: 'Garry verrier'
        },
        {
          USER_ID: 'Tristan michel'
        },
        {
          USER_ID: 'Tristan michel'
        },
        {
          USER_ID: 'Tristan michel'
        },
        {
          USER_ID: 'Garry verrier'
        },
      ]
    },
    {
      label: 'ITE-BMDE',
      members: [
        {
          USER_ID: 'Garry verrier'
        },
        {
          USER_ID: 'Tristan michel'
        }, {
          USER_ID: 'Garry verrier'
        }, {
          USER_ID: 'Garry verrier'
        }, {
          USER_ID: 'Garry verrier'
        },
      ]
    },
    {
      label: 'ITE-BMDI',
      members: [
        {
          USER_ID: 'Garry verrier'
        },
        {
          USER_ID: 'Tristan michel'
        }
      ]
    },
    {
      label: 'ITE-BMDH',
      members: [
        {
          USER_ID: 'Garry verrier'
        },
        {
          USER_ID: 'Tristan michel'
        }
      ]
    },
  ];
  members: User[] = [];
  applications: Application[] = [];
  showApps = false;
  showMembers = false;
  title = '';
  production = false;
  constructor(private breakpointObserver: BreakpointObserver,
              private apiService: ApiService,
              public dialog: MatDialog) {
    this.apiService.callConnection().subscribe();
    this.production = environment.production;
  }

  openDialog(): void {
    this.dialog.open(DashboardTeamsAddNeedsDialogComponent, {
      autoFocus: true,
      disableClose: true,
      data: {}
    });
  }

  showData(usergroup: Usergroup, value) {
    console.log('members', usergroup.members);
    console.log('applications', usergroup.applications);
    this.members = usergroup.members;
    this.applications = usergroup.applications;
    if (value === 'members' && usergroup.members !== undefined) {
      this.title = 'My members';
      this.showMembers = true;
      this.showApps = false;
    } else if (usergroup.members === undefined && this.showApps) {
      this.showMembers = false;
      this.showApps = false;
    }
    if (value === 'applications' && usergroup.applications !== undefined) {
      this.title = 'My applications';
      this.showMembers = false;
      this.showApps = true;
    } else if (usergroup.applications === undefined && this.showMembers) {
      this.showApps = false;
      this.showMembers = false;
    }
  }

  openMembersDialog(): void {
    this.dialog.open(DashboardTeamsAddMembersDialogComponent, {
      autoFocus: false,
      disableClose: false,
      data: {}
    });
  }


}
