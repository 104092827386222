<mat-icon (click)="closeDialog()" type="button" style="color: #009EE0; float: right;" aria-label="Cancel_icon">cancel
</mat-icon>

  <div class="title">
    <h2 mat-dialog-title>{{ "Entities.Skills.Dialog.RateTitle" | translate }}</h2>
  </div>
  <br/>
  <mat-dialog-content>
    <div class="title">
      <label class="input-label">{{ "Entities.Skills.Dialog.SelectTitle" | translate }}</label>
    </div>
    <!--Select Skills-->
    <label *ngIf="Skills.length === 0" class="input-label text-center align-items-center" style="color: red; border-radius: 8px; background-color: #FFFFFF;">No skills to rate</label>
    <ng-container *ngIf="Skills.length > 0">

    <mat-form-field appearance="fill">
      <input type="text" placeholder="Enter text to find skill" aria-label="Enter text to find skill" matInput [matAutocomplete]="auto" [formControl]="skills" [disabled]="Skills.length === 0">
    </mat-form-field>

    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let skill of filteredSkills | async" [value]="selectedSkills">
        <div (click)="optionClicked($event, skill)">
          <mat-checkbox [checked]="skill.selected" (change)="toggleSelection(skill)" (click)="$event.stopPropagation()">
            {{skill.label}} - {{skill.category}} - {{skill.classification}}
          </mat-checkbox>
        </div>
      </mat-option>
    </mat-autocomplete>
    </ng-container>

    <button class="mat-raised-button button_cancel col-md-12"
            (click)="openSkillDialog({},'create')"> {{ "Dashboards.User.Buttons.CreateSkills" | translate }}
    </button>
    <br>
    <br>
    <div class="title">
      <label class="input-label">{{ "Entities.Skills.Dialog.RateTitle" | translate }}</label>
    </div>
    <div style="border-radius: 8px; background-color: #FFFFFF;">
      <div class="row">
        <div class="col-6 m-auto" style="cursor: default">
          <div>
            <span *ngIf="this.stars == 1" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
              star
            </span>
            <span *ngIf="this.stars == 1" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
              star_border
            </span>
            <span *ngIf="this.stars == 1" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
              star_border
            </span>
            <span *ngIf="this.stars == 1" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
              star_border
            </span>
            <span *ngIf="this.stars != 1" class="material-icons" [ngStyle]="{'color':'#666666'}">
              star
            </span>
            <span *ngIf="this.stars != 1" class="material-icons" [ngStyle]="{'color':'#666666'}">
              star_border
            </span>
            <span *ngIf="this.stars != 1" class="material-icons" [ngStyle]="{'color':'#666666'}">
              star_border
            </span>
            <span *ngIf="this.stars != 1" class="material-icons" [ngStyle]="{'color':'#666666'}">
              star_border
            </span>
            <span class="material-icons" [ngStyle]="{'color':'#009EE0'}" #tooltip="matTooltip"
                  matTooltip='{{ "GetStarted.Standards.3" | translate }}'>
              info
            </span>
            <button class="buttoncustomclicked" (click)="setStarsClick(1)" *ngIf="this.stars == 1">{{ "Dashboards.StarRating.1" | translate }}</button>
            <button class="buttoncustom" (click)="setStarsClick(1)" *ngIf="this.stars != 1">{{ "Dashboards.StarRating.1" | translate }}</button>
          </div>
        </div>
        <div class="col-6 m-auto" style="cursor: default">
        <span *ngIf="this.stars == 2" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
              star
            </span>
          <span *ngIf="this.stars == 2" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
              star
            </span>
          <span *ngIf="this.stars == 2" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
              star_border
            </span>
          <span *ngIf="this.stars == 2" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
              star_border
            </span>
          <span *ngIf="this.stars != 2" class="material-icons" [ngStyle]="{'color':'#666666'}">
              star
            </span>
          <span *ngIf="this.stars != 2" class="material-icons" [ngStyle]="{'color':'#666666'}">
              star
            </span>
          <span *ngIf="this.stars != 2" class="material-icons" [ngStyle]="{'color':'#666666'}">
              star_border
            </span>
          <span *ngIf="this.stars != 2" class="material-icons" [ngStyle]="{'color':'#666666'}">
              star_border
            </span>
          <span class="material-icons" [ngStyle]="{'color':'#009EE0'}" #tooltip="matTooltip"
                matTooltip='{{ "GetStarted.Standards.4" | translate }}'>
              info
            </span>
          <button class="buttoncustomclicked" (click)="setStarsClick(2)" *ngIf="this.stars == 2">{{ "Dashboards.StarRating.2" | translate }}
          </button>
          <button class="buttoncustom" (click)="setStarsClick(2)" *ngIf="this.stars != 2">{{ "Dashboards.StarRating.2" | translate }}
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-6 m-auto" style="cursor: default">
        <span *ngIf="this.stars == 3" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
              star
            </span>
          <span *ngIf="this.stars == 3" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
              star
            </span>
          <span *ngIf="this.stars == 3" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
              star
            </span>
          <span *ngIf="this.stars == 3" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
              star_border
            </span>
          <span *ngIf="this.stars != 3" class="material-icons" [ngStyle]="{'color':'#666666'}">
              star
            </span>
          <span *ngIf="this.stars != 3" class="material-icons" [ngStyle]="{'color':'#666666'}">
              star
            </span>
          <span *ngIf="this.stars != 3" class="material-icons" [ngStyle]="{'color':'#666666'}">
              star
            </span>
          <span *ngIf="this.stars != 3" class="material-icons" [ngStyle]="{'color':'#666666'}">
              star_border
            </span>
          <span class="material-icons" [ngStyle]="{'color':'#009EE0'}" #tooltip="matTooltip"
                matTooltip='{{ "GetStarted.Standards.5" | translate }}'>
              info
            </span>
          <button class="buttoncustomclicked" (click)="setStarsClick(3)" *ngIf="this.stars == 3">{{ "Dashboards.StarRating.3" | translate }}</button>
          <button class="buttoncustom" (click)="setStarsClick(3)" *ngIf="this.stars != 3">{{ "Dashboards.StarRating.3" | translate }}</button>
        </div>
        <div class="col-6 m-auto" style="cursor: default">
         <span *ngIf="this.stars == 4" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
              star
            </span>
          <span *ngIf="this.stars == 4" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
              star
            </span>
          <span *ngIf="this.stars == 4" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
              star
            </span>
          <span *ngIf="this.stars == 4" class="material-icons" [ngStyle]="{'color':'#009EE0'}">
              star
            </span>
          <span *ngIf="this.stars != 4" class="material-icons" [ngStyle]="{'color':'#666666'}">
              star
            </span>
          <span *ngIf="this.stars != 4" class="material-icons" [ngStyle]="{'color':'#666666'}">
              star
            </span>
          <span *ngIf="this.stars != 4" class="material-icons" [ngStyle]="{'color':'#666666'}">
              star
            </span>
          <span *ngIf="this.stars != 4" class="material-icons" [ngStyle]="{'color':'#666666'}">
              star
            </span>
          <span class="material-icons" [ngStyle]="{'color':'#009EE0'}" #tooltip="matTooltip"
                matTooltip='{{ "GetStarted.Standards.6" | translate }}'>
              info
            </span>
          <button class="buttoncustomclicked" (click)="setStarsClick(4)" *ngIf="this.stars == 4">{{ "Dashboards.StarRating.4" | translate }}</button>
          <button class="buttoncustom" (click)="setStarsClick(4)" *ngIf="this.stars != 4">{{ "Dashboards.StarRating.4" | translate }}</button>
        </div>
      </div>
    </div>
    <br>
    <div class="row rate-popup-info-label" *ngIf="this.stars !== 0">
      <label class="col-12 input-label" *ngIf="this.stars == 1">{{ "GetStarted.Standards.3" | translate }}</label>
      <label class="col-12 input-label" *ngIf="this.stars == 2">{{ "GetStarted.Standards.4" | translate }}</label>
      <label class="col-12 input-label" *ngIf="this.stars == 3">{{ "GetStarted.Standards.5" | translate }}</label>
      <label class="col-12 input-label" *ngIf="this.stars == 4">{{ "GetStarted.Standards.6" | translate }}</label>
    </div>
  </mat-dialog-content>
  <br>
  <mat-dialog-actions align="center">
    <button class="mat-raised-button button_cancel col-md-3"
            (click)="closeDialog()">{{ "Buttons.Cancel" | translate }}</button>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <button class="mat-raised-button button_save col-md-3" (click)="saveRating(skills)"
            [disabled]="!choiceMade()">{{ "Buttons.Save" | translate }}</button>
  </mat-dialog-actions>

