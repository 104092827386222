<mat-icon (click)="closeDialog()" type="button" style="color: #009EE0; float: right;" aria-label="Cancel_icon">cancel</mat-icon>
<br>
<ng-container>
    <div class="row">
      <div class="title col-md-4">
        <h2 mat-dialog-title *ngIf="typeOfDialog === 'create'">{{ "Entities.Skills.Dialog.CreateTitle" | translate }}</h2>
        <h2 mat-dialog-title *ngIf="typeOfDialog === 'edit'">{{ "Entities.Skills.Dialog.EditTitle" | translate }}</h2>
      </div>

      <!--Active-->
      <div class="col-md-4 row">
        <div class="slide col">
          <br>
          <label class="input-label">{{ "Entities.Global.Fields.Active" | translate }}</label>
          &nbsp;&nbsp;&nbsp;
          <mat-slide-toggle [checked]="skill.active" [(ngModel)]="skill.active"></mat-slide-toggle>
        </div>
      </div>

      <div class="col-md-4">
      </div>
    </div>
    <mat-dialog-content>
      <div class="row">

        <!--Name-->
        <div class="col-md-4 m-auto">
          <label class="input-label" >{{ 'Entities.Global.Fields.Name' | translate }}</label>
          <br>
          <input type="text" class="input-text" [(ngModel)]="skill.label" id="label">
        </div>

        <!--Type-->
        <div class="col-md-4 m-auto">
          <label class="input-label" >{{ 'Entities.Skills.Fields.Type' | translate }}</label>
          <br>
          <mat-form-field class="input-dropdown" appearance="legacy">
            <mat-select [(ngModel)]="skill.category" id="category">
              <mat-option value="technical">{{ 'Entities.Skills.Fields.Types.Technical' | translate }}</mat-option>
              <mat-option value="functional">{{ 'Entities.Skills.Fields.Types.Functional' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!--Category-->
        <div class="col-md-4 m-auto">
          <label class="input-label">{{ 'Entities.Skills.Fields.Category' | translate }}</label>
          <br>
          <mat-form-field class="input-dropdown" appearance="legacy">
            <mat-select [(ngModel)]="skill.classification" id="classification">
              <mat-option value="tool">{{ 'Entities.Skills.Fields.Categories.Tool' | translate }}</mat-option>
              <mat-option value="role">{{ 'Entities.Skills.Fields.Categories.Role' | translate }}</mat-option>
              <mat-option value="programming_language">{{ 'Entities.Skills.Fields.Categories.programming_language' | translate }}</mat-option>
              <mat-option value="concept">{{ 'Entities.Skills.Fields.Categories.Concept' | translate }}</mat-option>
              <mat-option value="application">{{ 'Entities.Skills.Fields.Categories.Application' | translate }}</mat-option>
              <mat-option value="governance">{{ 'Entities.Skills.Fields.Categories.Governance' | translate }}</mat-option>
              <mat-option value="architecture">{{ 'Entities.Skills.Fields.Categories.Architecture' | translate }}</mat-option>
              <mat-option value="infrastructure">{{ 'Entities.Skills.Fields.Categories.Infrastructure' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>
    </mat-dialog-content>
</ng-container>
<mat-dialog-actions align="center">
  <button class="mat-raised-button button_cancel col-md-3" (click)="closeDialog()">{{ "Buttons.Cancel" | translate }}</button>
  &nbsp;&nbsp;&nbsp;&nbsp;
  <button class="mat-raised-button button_save col-md-3" (click)="saveData()" [disabled]="!fieldsAreFilled()">{{ "Buttons.Save" | translate }}</button>
</mat-dialog-actions>
