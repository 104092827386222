import { Skill } from '../../../models/skill.model';
import { SkillsService } from '../../../services/entities/skills.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ApiService } from '../../../services/api.service';
import { SkillsDialogComponent } from '../skills-dialog/skills-dialog.component';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.css']
})
export class SkillsComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['active', 'name', 'type', 'category', 'last_update_date', 'last_update_user', 'operations'];
  dataSource: MatTableDataSource<Skill>;

  Skills: Skill[];
  // POSITION OF SNACKBAR
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  // SET PAGINATOR AND SORT
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public dialog: MatDialog,
              private skillsService: SkillsService,
              private snackBar: MatSnackBar,
              private apiService: ApiService) {
    this.apiService.callConnection().subscribe();
  }

  // DIALOG FOR CREATE OR UPDATE A SKILL
  openDialog(skill, typeOfDialog): void {
    this.dialog.open(SkillsDialogComponent, {
      autoFocus: true, disableClose: false,
      data: {
        skill: {
          id: skill.SKILL_ID,
          label: skill.label,
          active: skill.active,
          category: skill.category,
          classification: skill.classification
        },
        typeOfDialog
      }
    }).afterClosed().subscribe(() => {
      this.retrieveAllSkills();
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit(): void {
    //this.retrieveAllSkills();

  }

  ngAfterViewInit() {
    const sortState: Sort = { active: 'last_update_date', direction: 'desc' };
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
    this.retrieveAllSkills();
  }

  retrieveAllSkills(): void {
    this.skillsService.getAllSkills()
      .subscribe(
        data => {
          this.Skills = data;
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        },
        error => {
          console.log(error);
        });
  }

  setUse(event: MatSlideToggleChange, skill: Skill) {
    skill.active = event.checked;
    this.skillsService.updateSkill(skill)
      .subscribe(
        data => {
          this.snackBar.open('A skill has been modified', 'OK', {
            duration: 4000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['snackbar-success']
          });
          this.retrieveAllSkills();
        },
        error => {
          this.snackBar.open('ERROR for modify a skill', 'OK', {
            duration: 4000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['snackbar-error']
          });
        });
  }

}
