import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {map, tap} from 'rxjs/operators';

const baseUrl = environment.API_BASE_URL;
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: sessionStorage.getItem('authToken')
  }),
 body: {}
};

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  getUser(): Observable<any>{
    return this.http.get(`${baseUrl}user`, httpOptions);
  }

  getUserAbilities(): Observable<any>{
    return this.http.get(`${baseUrl}user-list_abilities`, httpOptions);
  }

  getAllUsersAbilities(): Observable<any>{
    return this.http.get(`${baseUrl}user-search_profile`, httpOptions);
  }

  getUserUnacquiredSkills(): Observable<any>{
    return this.http.get(`${baseUrl}user-list_unacquired_skills`, httpOptions);
  }

  getUserLastAbilities(): Observable<any>{
    return this.http.get(`${baseUrl}user-list_summarized_abilities`, httpOptions);
  }

  getProfile(): Observable<any>{
    return this.http.get(`${baseUrl}user-get_profile`, httpOptions);
  }

  getMembers(): Observable<any>{
    return this.http.get(`${baseUrl}user-list_primary_informations`, httpOptions);
  }

  createAbilities(data): Observable<any>{
    httpOptions.body = data;
    return this.http.request<string>('put', `${baseUrl}user-add_abilities`, httpOptions).pipe(
      map((result: any) => result as string),
      tap((result: string) => {
        console.log('response from server:', result);
      }
    ));
  }

  updateAbilities(data): Observable<any>{
    httpOptions.body = data;
    return this.http.request<string>('put', `${baseUrl}user-update_abilities`, httpOptions).pipe(
      map((result: any) => result as string),
      tap((result: string) => {
          console.log('response from server:', result);
        }
      ));
  }

  updateAbility(data): Observable<any>{
    httpOptions.body = data;
    return this.http.request<string>('put', `${baseUrl}user-disable_abilities`, httpOptions).pipe(
      map((result: any) => result as string),
      tap((result: string) => {
          console.log('response from server:', result);
        }
      ));
  }

  updateProfile(data): Observable<any>{
    httpOptions.body = data;
    return this.http.request<string>('put', `${baseUrl}user-update_profile`, httpOptions).pipe(
      map((result: any) => result as string),
      tap((result: string) => {
          console.log('response from server:', result);
        }
      ));
  }

}
