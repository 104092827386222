import { TabsMatrixOptionsComponent } from './components/navs/tabs-matrix-options/tabs-matrix-options.component';
import { TabsEntitiesComponent } from './components/navs/tabs-entities/tabs-entities.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GetStartedComponent } from './components/get-started/get-started.component';
import { HelpComponent } from './components/help/help.component';
import { ProfileComponent } from './components/profile/profile.component';
import { MyMatrixComponent } from './components/my-matrix/my-matrix.component';
import { TabsDashboardsComponent } from './components/navs/tabs-dashboards/tabs-dashboards.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SearchComponent } from './components/search/search.component';

const routes: Routes = [
  { path: '', redirectTo: 'Dashboards', pathMatch: 'full' },
  { path: 'Dashboards', component: TabsDashboardsComponent },
  { path: 'Entities', component: TabsEntitiesComponent },
  { path: 'My-Matrix', component: MyMatrixComponent },
  { path: 'Matrix-Options', component: TabsMatrixOptionsComponent },
  { path: 'Get-Started', component: GetStartedComponent },
  { path: 'Help-&-Support', component: HelpComponent },
  { path: 'Profile', component: ProfileComponent },
  { path: 'Search' , component:SearchComponent},
  // NOT FOUND PAGE
  { path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
