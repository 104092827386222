<nav class="navbar navbar-expand-lg" style="  background-color: #FFFFFF; box-shadow: 0 2px 4px 0 rgba(0,0,0,0.14); ">
  <a class="navbar-brand" href="Dashboards">
    <img class="col-6" alt="logo_vallourec" src="assets/img/logo_Vallourec.svg">
    <span class="line"></span>
    <span class="title_skillmatrix col-1">SKILLMATRIX</span></a>
  <button class="navbar-toggler" type="button" (click)="toggleNavbar()" style="background-color: #009EE0; color: white">
    <span style="color: white">○○○</span>
  </button>


  <div class="collapse navbar-collapse show" [ngClass]="{ 'show': navbarOpen }">
    <div class="mx-auto">
      <ul class="navbar-nav">
        <li class="nav-item active">
          <a class="nav-link" href="Dashboards" style="color: #009EE0">{{ "Dashboards.Title" | translate}}</a>
        </li>
        <li class="nav-item active" *ngIf="job == 'manager' || job == 'administrator'">
          <a class="nav-link" href="Entities" style="color: #009EE0">{{ "Entities.Title" | translate}}</a>
        </li>
        
        <li class="nav-item active">
          <a class="nav-link" href="Get-Started" style="color: #009EE0">{{ "GetStarted.Title" | translate }}</a>
        </li>
        
        <li class="nav-item active">
          <a class="nav-link" href="Search" style="color: #009EE0">Search</a>
        </li>
        <!--
        <li class="nav-item active">
          <a class="nav-link" href="Help-&-Support" style="color: #009EE0">{{ "SearchHelp.Title" | translate}}</a>
        </li>-->
        <li class="nav-item active">
          <a class="nav-link" href="https://web.microsoftstream.com/channel/7640dcc3-f286-4911-ac8a-c9955de7849a"
             style="color: #009EE0">{{ "Support.Title" | translate}}</a>
        </li>
      </ul>
    </div>
    <div class="form-inline my-2 my-lg-0">
      <a class="my-2 my-sm-0" style="color: #009EE0; text-decoration: none;" href="Profile">
        <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-person-circle" fill="currentColor"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z"></path>
          <path fill-rule="evenodd" d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
          <path fill-rule="evenodd" d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"></path>
        </svg>
      </a>
      &nbsp;&nbsp;
      <span class="form-inline">
        <select class="form-control" #selectedLang (change)="useLanguage(selectedLang.value)">
          <option *ngFor="let language of translate.langs" [value]="language" [selected]="language === translate.currentLang">
            {{ language }}
          </option>
        </select>
      </span>
<!--      <mat-form-field>-->
<!--        <mat-select [(ngModel)]="selectedLanguage" (ngModelChange)="useLanguage(selectedLanguage)">-->
<!--          <mat-option *ngFor="let language of translate.langs" [value]="language">-->
<!--            {{language}}-->
<!--          </mat-option>-->
<!--        </mat-select>-->
<!--      </mat-form-field>-->
    </div>
  </div>
</nav>
