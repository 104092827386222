<mat-icon (click)="closeDialog()" type="button" style="color: #009EE0; float: right;" aria-label="Cancel_icon">cancel</mat-icon>
<ng-container>
  <div class="title">
    <h2 mat-dialog-title *ngIf="typeOfDialog === 'create'">{{ "Entities.Usergroups.Dialog.CreateTitle" | translate }}</h2>
    <h2 mat-dialog-title *ngIf="typeOfDialog === 'edit'">{{ "Entities.Usergroups.Dialog.EditTitle" | translate }}</h2>
  </div>
  <br>
  <mat-dialog-content>
    <div class="row">
      <div class="col-md-6 m-auto">
        <label class="input-label" >{{ 'Entities.Global.Fields.Name' | translate }}</label>
        <br>
        <input type="text" class="input-text" [(ngModel)]="usergroup.label" id="label">
      </div>
      <div class="col-md-6 m-auto">
        <label class="input-label" >{{ 'Entities.Global.Fields.Manager' | translate }}</label>
        <br>
        <input type="email" class="input-text" [(ngModel)]="usergroup.manager.USER_ID" id="labelmanager">
      </div>
      <div class="col-md-6 m-auto">
      <label class="input-label" >{{ 'Entities.Global.Fields.Parent' | translate }}</label>
      <mat-form-field appearance="fill">

        <mat-select [(ngModel)]="usergroup.parent" [compareWith]="objectComparisonFunction">
          <mat-option [value]="noparent">--No Parent--</mat-option>
          <mat-option *ngFor="let parent of Usergroups" [value]="parent">
            {{parent.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      </div>
      <div class="col-md-6 m-auto">
        <label class="input-label">{{ 'Entities.Global.Fields.Active' | translate }}</label>
        <br>
        <mat-slide-toggle [(ngModel)]="usergroup.active" [checked]="usergroup.active"></mat-slide-toggle>
      </div>
    </div>
  </mat-dialog-content>
</ng-container>
<mat-dialog-actions align="center">
  <button class="mat-raised-button button_cancel col-md-3" (click)="closeDialog()">{{ "Buttons.Cancel" | translate }}</button>
  &nbsp;&nbsp;&nbsp;&nbsp;
  <button class="mat-raised-button button_save col-md-3" [disabled]="!fieldsAreFilled()" (click)="saveData()">{{ "Buttons.Save" | translate }}</button>
</mat-dialog-actions>
