import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Skill} from '../../../models/skill.model';
import {SkillsService} from '../../../services/entities/skills.service';
import {ApiService} from '../../../services/api.service';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';

@Component({
  selector: 'app-dashboard-user-add-skill-dialog',
  templateUrl: './dashboard-user-add-skill-dialog.component.html',
  styleUrls: ['./dashboard-user-add-skill-dialog.component.css']
})
export class DashboardUserAddSkillDialogComponent implements OnInit {

  typeOfDialog = '';

  skill: Skill = {
    SKILL_ID: '',
    label: '',
    active: true,
    category: '',
    classification: ''
  };

  // POSITION OF SNACKBAR
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';


  constructor(private dialogRef: MatDialogRef<DashboardUserAddSkillDialogComponent>,
    private skillsService: SkillsService,
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) data) {
      this.typeOfDialog = data.typeOfDialog;
    this.skill.SKILL_ID = data.skill.id;
    this.skill.label = data.skill.label;
    // set active true by default on create
    this.skill.active = data.typeOfDialog === 'edit' ? data.skill.active : this.skill.active = true;
    this.skill.category = data.skill.category;
    this.skill.classification = data.skill.classification;
     }

  ngOnInit(): void {
  }

  saveData() {

    if (!this.skill.SKILL_ID) {

      const data = {
        label: this.skill.label,
        active: true,
        category: this.skill.category,
        classification: this.skill.classification
      };

      this.skillsService.createSkill(data)
        .subscribe(
          (res: HttpResponse<Skill>) => {
            this.closeDialog();
            this.snackBar.open('A skill has been created', 'OK', {
              duration: 4000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['snackbar-success']
            });
          },
          (error: HttpErrorResponse) => {
            this.closeDialog();
            this.snackBar.open('ERROR for create a skill', 'OK', {
              duration: 4000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['snackbar-error']
            });
          }
        );
    } else {

      const data = {
        SKILL_ID: this.skill.SKILL_ID,
        label: this.skill.label,
        active: this.skill.active,
        category: this.skill.category,
        classification: this.skill.classification
      };

      this.skillsService.updateSkill(data)
        .subscribe(
          (res: HttpResponse<Skill>) => {
            this.closeDialog();
            this.snackBar.open('A skill has been modified', 'OK', {
              duration: 4000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['snackbar-success']
            });
          },
          (error: HttpErrorResponse) => {
            this.closeDialog();
            this.snackBar.open('ERROR for modify a skill', 'OK', {
              duration: 4000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['snackbar-error']
            });
          }
        );
    }
  }

  fieldsAreFilled() {
    const nameOk = !(this.skill.label === '' || this.skill.label === undefined);
    const typeOk = !(this.skill.category === '' || this.skill.category === undefined);
    const categoryOk = !(this.skill.classification === '' || this.skill.classification === undefined);
    return nameOk && typeOk && categoryOk;
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
