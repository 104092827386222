<h2 mat-dialog-title>Create New Usergroup</h2>

<mat-dialog-content class="mat-typography">
  <br />

  <div>
    <div *ngIf="showMsgSuccess">
      <p class="alert alert-success">
        New rating added with success !
      </p>
      <br />
    </div>

    <div *ngIf="showMsgError">
      <p class="alert alert-danger">
        Error during the save of the rating...
      </p>
      <br />
    </div>
  </div>

  <div class="col-md-12">
    <div class="col-md-12">
      <select class="input-text" id="value">
        <option>Empty</option>
        <option>None</option>
        <option>Beginner</option>
        <option>Intermediate</option>
        <option>Advanced</option>
        <option>Expert</option>
      </select>
    </div>

    <br />

    <div class="col-md-12">
      <select class="input-text" id="competence">
        <option>Angular</option>
        <option>Java</option>
        <option>ReactJS</option>
        <option>AWS - API Gataway</option>
        <option>Terraform</option>
        <option>Android</option>
      </select>
    </div>

    <br />

    <div class="col-md-12">
      <input type="text" class="input-text" value="antoine.blois" readonly>
    </div>
  </div>
</mat-dialog-content>

<br />

<mat-dialog-actions align="end">
  <button mat-raised-button style="color: #009EE0; background-color: #FFFFFF" (click)="close()">Cancel</button>
  <button mat-raised-button style="background-color: #009EE0; color: #FFFFFF" (click)="save()">Save</button>
</mat-dialog-actions>
