<br />

<mat-tab-group color="primary" mat-align-tabs="start">

  <mat-tab label="{{ 'Menu_Matrix_Options_Import_Matrix' | translate }}">
    <app-import-matrix></app-import-matrix>
  </mat-tab>
  <mat-tab label="{{ 'Menu_Matrix_Options_Export_Matrix' | translate }}">
    <app-export-matrix></app-export-matrix>
  </mat-tab>
  <mat-tab label="{{ 'Menu_Matrix_Options_Team_Matrix' | translate }}">
    <app-matrix-usergroups></app-matrix-usergroups>
  </mat-tab>
</mat-tab-group>
