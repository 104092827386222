<div class="grid-container">
  <mat-grid-list cols="{{ ( cardLayout | async )?.columns }}" rowHeight="200px">

    <!--Mini Cards-->
    <mat-grid-tile *ngIf="!production" [colspan]="( cardLayout | async )?.miniCard?.cols" [rowspan]="( cardLayout | async )?.miniCard?.rows">
      <app-dashboard-card [displayMenu]="false" title="{{ 'Dashboards.User.Minicards.Title1' | translate }}">
        <div>
          {{ 'Dashboards.User.UnderDevelopment' | translate }}
          <!--<h2>7</h2>-->
        </div>
      </app-dashboard-card>
    </mat-grid-tile>

    <mat-grid-tile *ngIf="!production" [colspan]="( cardLayout | async )?.miniCard?.cols" [rowspan]="( cardLayout | async )?.miniCard?.rows">
      <app-dashboard-card [displayMenu]="false" title="{{ 'Dashboards.User.Minicards.Title2' | translate }}">
        <div>
          {{ 'Dashboards.User.UnderDevelopment' | translate }}
          <!--<h2>16/18</h2>-->
        </div>
      </app-dashboard-card>
    </mat-grid-tile>

    <mat-grid-tile *ngIf="!production" [colspan]="( cardLayout | async )?.miniCard?.cols" [rowspan]="( cardLayout | async )?.miniCard?.rows">
      <app-dashboard-card [displayMenu]="false" title="{{ 'Dashboards.User.Minicards.Title3' | translate }}">
        <div>
          {{ 'Dashboards.User.UnderDevelopment' | translate }}
          <!--<h2>45/137</h2>-->
        </div>
      </app-dashboard-card>
    </mat-grid-tile>

    <mat-grid-tile [colspan]="( cardLayout | async )?.miniCard?.cols" [rowspan]="( cardLayout | async )?.miniCard?.rows">
      <app-dashboard-card [displayMenu]="false">
        <app-dashboard-user-buttons></app-dashboard-user-buttons>
      </app-dashboard-card>
    </mat-grid-tile>

    <!--Charts-->
    <mat-grid-tile *ngIf="!production" [colspan]="( cardLayout | async )?.chart?.cols" [rowspan]="( cardLayout | async )?.chart?.rows">
      <app-dashboard-card title="{{ 'Dashboards.User.RatingsEvolutionChart.Title' | translate }}" [displayMenu]="false">
        {{ 'Dashboards.User.UnderDevelopment' | translate }}
        <!--<app-dashboard-user-abilities-evolution-chart></app-dashboard-user-abilities-evolution-chart>-->
      </app-dashboard-card>
    </mat-grid-tile>
    <ng-container *ngIf="!production">
    <mat-grid-tile *ngFor="let i of [6]" [colspan]="( cardLayout | async )?.chart?.cols"
      [rowspan]="( cardLayout | async )?.chart.rows">
      <app-dashboard-card title=" {{ 'Dashboards.User.RatingsDistributionChart.Title' | translate }} " [displayMenu]="false">
        {{ 'Dashboards.User.UnderDevelopment' | translate }}
        <!--<app-dashboard-user-distribution-chart></app-dashboard-user-distribution-chart>-->
      </app-dashboard-card>
    </mat-grid-tile>
    </ng-container>
    <!--Table-->
    <mat-grid-tile [colspan]="( cardLayout | async )?.table?.cols" [rowspan]="( cardLayout | async )?.table?.rows">
      <app-dashboard-card title="{{ 'Dashboards.User.RatingsTable.Title' | translate }}" class="fit-content-card" [displayMenu]="false">
        <app-dashboard-user-abilities-table></app-dashboard-user-abilities-table>
      </app-dashboard-card>
    </mat-grid-tile>

  </mat-grid-list>
</div>
