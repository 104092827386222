import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {LoadingScreenService} from '../../services/loading-screen/loading-screen.service';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.css']
})
export class LoadingScreenComponent implements OnInit, OnDestroy {
  loading = false;
  loadingSubscription: Subscription;

  constructor(private loadingScreenService: LoadingScreenService) {
  }

  ngOnInit() {
    // this.loadingSubscription = this.loadingScreenService.loadingStatus.pipe(
    //   debounceTime(1000) // bug lorsqu'on met inferieur à 1 seconde, le loading s'affiche lorsqu'on ouvre une popup
    // ).subscribe((value) => {
    //   this.loading = value;
    // });
  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }

}
