<div class="col-12 data-table">

  <mat-form-field class="col-md-12 filter">
    <mat-label>Search</mat-label>
    <input matInput style="background-color: rgb(235, 233, 233);" (keyup)="applyFilter($event)" placeholder="Search by name,date,type,etc..."
           #input>
    <mat-icon aria-label="Search_icon">search</mat-icon>
  </mat-form-field>

  <table mat-table matTableResponsive [dataSource]="dataSource" matSort>

        <!-- Name Column -->
        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Username</th>
          <td mat-cell *matCellDef="let ability">{{ability.USER_ID}}</td>
        </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="skill_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "Dashboards.User.RatingsTable.SkillName" | translate }}</th>
      <td mat-cell *matCellDef="let ability">{{ability.label}}</td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="skill_type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "Dashboards.User.RatingsTable.SkillType" | translate }}</th>
      <td mat-cell *matCellDef="let ability">{{ability.category}}</td>
    </ng-container>

    <!-- Category Column -->
    <ng-container matColumnDef="skill_category">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "Dashboards.User.RatingsTable.SkillCategory" | translate }}</th>
      <td mat-cell *matCellDef="let ability">{{ability.classification}}</td>
    </ng-container>

    <!-- Last Rating Value Column -->
    <ng-container matColumnDef="last_rating_value">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "Dashboards.User.RatingsTable.LastHistoryValue" | translate }}</th>
      <td mat-cell *matCellDef="let ability">
        <app-star-rating [rating]="ability.rating"></app-star-rating>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let skill; columns: displayedColumns;"></tr>

  </table>

  <mat-paginator *ngIf="mobile" [pageSize]="2" [hidePageSize]="true" style="display: flex; justify-content: center"></mat-paginator>
  <mat-paginator *ngIf="!mobile" [pageSize]="10" [hidePageSize]="true" style="display: flex; justify-content: center"></mat-paginator>
</div>

