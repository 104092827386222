import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Usergroup } from '../../../models/usergroup.model';
import { UsergroupsService } from '../../../services/entities/usergroups.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-usergroups-dialog',
  templateUrl: './usergroups-dialog.component.html',
  styleUrls: ['./usergroups-dialog.component.css']
})
export class UsergroupsDialogComponent implements OnInit {

  // PREPARE VARIABLES
  typeOfDialog = '';

  manager: User = {
    USER_ID: ''
  };

  parent: Usergroup = {
    USER_GROUP_ID: '',
    label: '',
    active: true,
    manager:
    {
      USER_ID: ''
    },
    parent: {
      USER_GROUP_ID: '',
      label: ''
    }
  };

  usergroup: Usergroup = {
    USER_GROUP_ID: '',
    label: '',
    active: true,
    manager: this.manager,
    parent: this.parent
  };
  // for noparent option in the parent mat-select
  noparent: Usergroup = {
    USER_GROUP_ID: '',
    label: '--No parent--'
  };

  usergroups = new FormControl();
  Usergroups: Usergroup[] = [];
  selectedUserGroup: Usergroup = {
    USER_GROUP_ID: '',
    label: ''
  };
  filteredUserGroups: Observable<Usergroup[]>;
  lastFilter = '';
  // POSITION OF SNACKBAR
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private dialogRef: MatDialogRef<UsergroupsDialogComponent>,
              private usergroupsService: UsergroupsService,
              private snackBar: MatSnackBar,
              private userGroupsService: UsergroupsService,
              @Inject(MAT_DIALOG_DATA) data) {
    this.typeOfDialog = data.typeOfDialog;
    this.usergroup.USER_GROUP_ID = data.usergroup.USER_GROUP_ID;
    this.usergroup.label = data.usergroup.label;
    this.usergroup.parent = data.usergroup.parent === '' ? this.noparent : data.usergroup.parent;
    this.usergroup.manager = data.usergroup.manager === '' ? this.manager : data.usergroup.manager;
    // set active true by default on create
    this.usergroup.active = data.typeOfDialog === 'edit' ? data.usergroup.active : this.usergroup.active = true;
  }

  ngOnInit(): void {
    this.retrieveAllUsergroups();
    this.filteredUserGroups = this.usergroups.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  retrieveAllUsergroups(): void {
    const usergroup = this.usergroup.USER_GROUP_ID;
    this.userGroupsService.listPotentialParents(usergroup)
      .subscribe(
        data => {
          this.Usergroups = data;
        },
        error => {
          console.log(error);
        });
  }

  // SAVE USER_GROUP DATA
  saveData() {
    // CREATE A USER_GROUP
    if (!this.usergroup.USER_GROUP_ID) {

      const parent = {
        USER_GROUP_ID: this.usergroup.parent.USER_GROUP_ID,
        LABEL: this.usergroup.parent.label
      };

      const manager = {
        USER_ID: this.usergroup.manager.USER_ID
      };

      let data = {};

      if (this.usergroup.parent.USER_GROUP_ID === '' && this.usergroup.manager.USER_ID === '') {
        data = {
          USER_GROUP_ID: this.usergroup.USER_GROUP_ID,
          label: this.usergroup.label,
          active: this.usergroup.active
        };

      }
      else if (this.usergroup.manager.USER_ID === '') {
        data = {
          USER_GROUP_ID: this.usergroup.USER_GROUP_ID,
          label: this.usergroup.label,
          active: this.usergroup.active,
          parent
        };
      }
      else if (this.usergroup.parent.USER_GROUP_ID === '') {
        data = {
          USER_GROUP_ID: this.usergroup.USER_GROUP_ID,
          label: this.usergroup.label,
          active: this.usergroup.active,
          manager
        };
      }
      else{

        data = {
          label: this.usergroup.label,
          active: this.usergroup.active,
          manager,
          parent
        };
      }

      this.usergroupsService.createUserGroup(data)
        .subscribe(
          (res: HttpResponse<Usergroup>) => {
            this.closeDialog();
            this.snackBar.open('A usergroup has been created', 'OK', {
              duration: 4000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['snackbar-success']
            });
          },
          (error: HttpErrorResponse) => {
            this.closeDialog();
            this.snackBar.open('ERROR for create a usergroup', 'OK', {
              duration: 4000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['snackbar-error']
            });
          }
        );
    }
    // MODIFY A USER_GROUP
    else {

      const parent = {
        USER_GROUP_ID: this.usergroup.parent.USER_GROUP_ID,
        LABEL: this.usergroup.parent.label
      };

      const manager = {
        USER_ID: this.usergroup.manager.USER_ID
      };

      let data = {};

      if (this.usergroup.parent.USER_GROUP_ID === '' && this.usergroup.manager.USER_ID === '') {
        data = {
          USER_GROUP_ID: this.usergroup.USER_GROUP_ID,
          label: this.usergroup.label,
          active: this.usergroup.active
        };

      }
      else if (this.usergroup.manager.USER_ID === '') {
        data = {
          USER_GROUP_ID: this.usergroup.USER_GROUP_ID,
          label: this.usergroup.label,
          active: this.usergroup.active,
          parent
        };
      }
      else if (this.usergroup.parent.USER_GROUP_ID === '') {
        data = {
          USER_GROUP_ID: this.usergroup.USER_GROUP_ID,
          label: this.usergroup.label,
          active: this.usergroup.active,
          manager
        };
      }
      else{

        data = {
          USER_GROUP_ID: this.usergroup.USER_GROUP_ID,
          label: this.usergroup.label,
          active: this.usergroup.active,
          manager,
          parent
        };
      }

      this.usergroupsService.updateUserGroup(data)
        .subscribe(
          (res: HttpResponse<Usergroup>) => {
            this.closeDialog();
            this.snackBar.open('A usergroup has been modified', 'OK', {
              duration: 4000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['snackbar-success']
            });
          },
          (error: HttpErrorResponse) => {
            this.closeDialog();
            this.snackBar.open('ERROR for modify usergroup', 'OK', {
              duration: 4000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['snackbar-error']
            });
          }
        );
    }
  }

  // CLOSE POP-UP
  closeDialog() {
    this.dialogRef.close();
  }

  IsManagerValid(){
    const regex = new RegExp('^([a-zA-Z]+)([.])([a-zA-Z]+)([0-9]*)([@])(vallourec[.]com|ext[.]vallourec[.]com)$');
    let valueMatches: boolean;
    valueMatches = regex.test(this.usergroup.manager.USER_ID);
    return valueMatches;
  }

  fieldsAreFilled() {
    const nameOk = !(this.usergroup.label === '' || this.usergroup.label === undefined);
    const managerOk = ((this.usergroup.manager.USER_ID === '' || this.usergroup.manager.USER_ID === undefined) || this.IsManagerValid());
    return nameOk && managerOk;
  }

  displayFn(value: Usergroup[] | string): string | undefined {
    let displayValue: string;
    if (Array.isArray(value)) {
      value.forEach((usergroup, index) => {
        if (index === 0) {
          displayValue = usergroup.label;
        } else {
          displayValue += ', ' + usergroup.label;
        }
      });
    } else {
      displayValue = value;
    }
    return displayValue;
  }

  private _filter(value: string): Usergroup[] {
    const filterValue = value.toLowerCase();

    return this.Usergroups.filter(usergroup => usergroup.label.toLowerCase().indexOf(filterValue) === 0);
  }

  // tslint:disable-next-line:only-arrow-functions
  public objectComparisonFunction = function( option, value ): boolean {
    return option.USER_GROUP_ID === value.USER_GROUP_ID;
  };
}
