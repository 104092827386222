import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabs-entities',
  templateUrl: './tabs-entities.component.html',
  styleUrls: ['./tabs-entities.component.css']
})
export class TabsEntitiesComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
