import { Component, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-star-rating-new',
  templateUrl: './star-rating-new.component.html',
  styleUrls: ['./star-rating-new.component.css']
})
export class StarRatingNewComponent implements OnInit {
  clicked: boolean;
  stars: number;

  @Output() clickedItemEvent = new EventEmitter<boolean>();

  @Output() starsItemEvent = new EventEmitter<number>();

  constructor() {
    this.clicked = false;
    this.stars = 0;
   }

  ngOnInit(): void {
  }

  setStars(amount:number){
    if (this.clicked != true) {
      this.stars = amount;
    }
    
  }

  setStarsClick(amount:number){
    if (this.clicked == true)
    {
      this.stars = 0;
      this.clicked = false;
      this.starsItemEvent.emit(0);
      this.clickedItemEvent.emit(this.clicked);
    }
    else
    {
      this.stars = amount;
      this.clicked = true;
      this.starsItemEvent.emit(amount);
      this.clickedItemEvent.emit(this.clicked);
    }
  }

  choiceMade() {
    const isOk = !(this.stars == 0 || !this.clicked);
    return isOk;
  }

}
