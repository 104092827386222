<mat-icon (click)="closeDialog()" type="button" style="color: #009EE0; float: right;" aria-label="Cancel_icon">cancel</mat-icon>
<br>
<ng-container>

  <div>
    <div>
      <h2 mat-dialog-title>DELETE A SKILL</h2>
    </div>
  </div>

  <mat-dialog-content>

    You are going to delete your skill "{{abilities[0].skill.label}}"

    <br/>
    <br/>

  </mat-dialog-content>
</ng-container>

<mat-dialog-actions align="center">
  <button class="mat-raised-button button_cancel col-md-3" (click)="closeDialog()">{{ "Buttons.Cancel" | translate }}</button>
  &nbsp;&nbsp;&nbsp;&nbsp;
  <button class="mat-raised-button button_save col-md-3" (click)="updateAbility()">Delete</button>
</mat-dialog-actions>
