<div class="col-12 data-table">

<!--  <mat-form-field class="col-md-12 filter">-->
<!--    <mat-label>{{ "Entities.Global.Filter.Title" | translate }}</mat-label>-->
<!--    <input matInput style="background-color: rgb(235, 233, 233);" (keyup)="applyFilter($event)" placeholder="{{ 'Entities.Global.Filter.Text' | translate }}"-->
<!--           #input>-->
<!--    <mat-icon aria-label="Search_icon">search</mat-icon>-->
<!--  </mat-form-field>-->

  <table mat-table matTableResponsive [dataSource]="dataSource" matSort>

    <!-- Last Rating Date Column -->
    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let member">
        {{member.USER_ID}} {{member.label}}
      </td>
    </ng-container>

    <!-- Button Column -->
<!--    <ng-container matColumnDef="operations">-->
<!--      <th mat-header-cell *matHeaderCellDef>-->
<!--      </th>-->
<!--      <td mat-cell *matCellDef="let ability">-->
<!--        <button mat-icon-button style="color: #009EE0; float: right;">-->
<!--          <mat-icon (click)="openDisableDialog(ability, $event)">delete_forever</mat-icon>-->
<!--        </button>-->
<!--      </td>-->
<!--    </ng-container>-->

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let member; columns: displayedColumns;"></tr>

  </table>

  <mat-paginator *ngIf="mobile" [pageSize]="2" [hidePageSize]="true" style="display: flex; justify-content: center"></mat-paginator>
  <mat-paginator *ngIf="!mobile" [pageSize]="10" [hidePageSize]="true" style="display: flex; justify-content: center"></mat-paginator>
</div>
