import {Component, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Application} from '../../../models/application.model';
import {ApplicationsService} from '../../../services/entities/applications.service';
import {ApiService} from '../../../services/api.service';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';


@Component({
  selector: 'app-applications-dialog',
  templateUrl: './applications-dialog.component.html',
  styleUrls: ['./applications-dialog.component.css']
})
export class ApplicationsDialogComponent implements OnInit {

  // PREPARE VARIABLES
  typeOfDialog = '';
  application: Application = {
    label: '',
    global_id: '',
    active: true
  };

  // POSITION OF SNACKBAR
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private dialogRef: MatDialogRef<ApplicationsDialogComponent>, private applicationsService: ApplicationsService,
              private apiService: ApiService, private snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) data) {
    this.typeOfDialog = data.typeOfDialog;
    this.application.APPLICATION_ID = data.application.APPLICATION_ID;
    this.application.label = data.application.label;
    this.application.global_id = data.application.global_id;
    // set active true by default on create
    this.application.active = data.typeOfDialog === 'edit' ? data.application.active : this.application.active = true;
  }

  ngOnInit(): void {
  }

  // SAVE APPLICATION DATA
  saveData() {
    // CREATE AN APPLICATION
    if (!this.application.APPLICATION_ID) {
      const data = {
        label: this.application.label,
        active: this.application.active,
        global_id: this.application.global_id
      };
      this.applicationsService.createApplication(data)
        .subscribe(
          res => {
            this.closeDialog();
            this.snackBar.open('An application has been created', 'OK', {
              duration: 4000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['snackbar-success']
              });
          },
          error => {
            this.snackBar.open('ERROR for create an application', 'OK', {
              duration: 4000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['snackbar-error']
            });
            this.closeDialog();
          }
        );
    }
    // MODIFY AN APPLICATION
    else {
      const data = {
        APPLICATION_ID: this.application.APPLICATION_ID,
        label: this.application.label,
        global_id: this.application.global_id,
        active: this.application.active
      };
      this.applicationsService.updateApplication(data)
        .subscribe(
          res => {
            this.closeDialog();
            this.snackBar.open('An application has been modified', 'OK', {
              duration: 4000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['snackbar-success']
              });
          },
          error => {
            this.snackBar.open('ERROR for modify an application', 'OK', {
              duration: 4000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['snackbar-error']
              });
            this.closeDialog();
          }
        );
    }
  }

  fieldsAreFilled() {
    const nameOk = !(this.application.label === '' || this.application.label === undefined);
    const keyOk = !(this.application.global_id === '' || this.application.global_id === undefined);
    return nameOk && keyOk;
  }

  // CLOSE POP-UP
  closeDialog() {
    this.dialogRef.close();
  }
}
