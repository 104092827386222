import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {Skill} from '../../../models/skill.model';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material/dialog';
import {SkillsService} from '../../../services/entities/skills.service';
import {ApiService} from '../../../services/api.service';
import {RatingsService} from '../../../services/entities/ratings.service';
import {FormControl} from '@angular/forms';
import {MatRadioChange} from '@angular/material/radio';
import {UserService} from 'src/app/services/entities/user.service';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {SharedService} from '../../../services/shared.service';
import { DashboardUserAddSkillDialogComponent } from '../dashboard-user-add-skill-dialog/dashboard-user-add-skill-dialog.component';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-user-add-abilities-dialog',
  templateUrl: './dashboard-user-add-abilities-dialog.component.html',
  styleUrls: ['./dashboard-user-add-abilities-dialog.component.css']
})
export class DashboardUserAddAbilitiesDialogComponent implements OnInit {

  currentUser: any;

  typeOfDialog = '';

  choice = '';

  skills = new FormControl();

  Skills: Skill[] = [];

  selectedSkills: Skill[] = new Array<Skill>();

  filteredSkills: Observable<Skill[]>;
  lastFilter = '';

  addClick(IsClicked: boolean) {
    this.clicked = IsClicked;
  }

  addStars(StarsNb: number) {
    this.stars = StarsNb;
  }


  /* skill: Skill = {
     SKILL_ID: '',
     label: '',
     active: true,
     last_update_user: '',
     last_update_date: '',
     class: '',
     class: ''
   }; */

  // POSITION OF SNACKBAR
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  selectedValue = 0;

  stars: number;
  clicked: boolean;


  constructor(private dialogRef: MatDialogRef<DashboardUserAddAbilitiesDialogComponent>,
              private ratingsService: RatingsService,
              private apiService: ApiService,
              private changeDetectorRefs: ChangeDetectorRef,
              private snackBar: MatSnackBar,
              private skillsService: SkillsService,
              private usersService: UserService,
              private sharedService: SharedService,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) data) {

    this.stars = 0;
    this.clicked = false;
  }

  retrieveUnacquiredSkills(): void {
    this.usersService.getUserUnacquiredSkills()
      .subscribe(
        data => {
          this.Skills = data;
        },
        error => {
          console.log(error);
        });
  }

  ngOnInit(): void {
    this.retrieveUnacquiredSkills();
    this.filteredSkills = this.skills.valueChanges.pipe(
      startWith<string | Skill[]>(''),
      map(value => typeof value === 'string' ? value : this.lastFilter),
      map(filter => this.filter(filter))
    );
  }

  openSkillDialog(skill, typeOfDialog): void {
    this.dialog.open(DashboardUserAddSkillDialogComponent, {
      autoFocus: true, disableClose: false,
      data: {
        skill: {
          id: skill.SKILL_ID,
          label: skill.label,
          active: skill.active,
          category: skill.category,
          classification: skill.classification
        },
        typeOfDialog
      }
    }).afterClosed().subscribe(() => {
      this.retrieveUnacquiredSkills();
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  choiceMade() {
    return !(this.stars === 0 || this.skills.value == null);
  }

  saveRating(skills: any) {
    console.log(skills.value);
    console.log(this.stars);

    const data = {
      skills: this.skills.value,
      value: this.stars
    };

    console.log(data);

    this.usersService.createAbilities(data)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.closeDialog();
          this.snackBar.open('Skills have been rated', 'OK', {
            duration: 4000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['snackbar-success']
          });
          this.sharedService.callFunctionEvent();
        },
        (error: HttpErrorResponse) => {
          this.closeDialog();
          this.snackBar.open('ERROR for rating these skills', 'OK', {
            duration: 4000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['snackbar-error']
          });
        }
      );
  }

  radioChange(event: MatRadioChange) {
    this.selectedValue = event.value;
  }

  setStars(amount: number) {
    if (this.clicked !== true) {
      this.stars = amount;
    }

  }

  setStarsClick(amount: number) {
      this.stars = amount;
      console.log(this.stars);
  }

  filter(filter: string): Skill[] {
    this.lastFilter = filter;
    if (filter) {
      return this.Skills.filter(option => {
        return option.label.toLowerCase().indexOf(filter.toLowerCase()) >= 0
          || option.classification.toLowerCase().indexOf(filter.toLowerCase()) >= 0
          || option.category.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      });
    } else {
      return this.Skills.slice();
    }
  }

  displayFn(value: Skill[] | string): string | undefined {
    let displayValue: string;
    if (Array.isArray(value)) {
      value.forEach((skill, index) => {
        if (index === 0) {
          displayValue = skill.label + ' ' + skill.classification + ' ' + skill.category;
        } else {
          displayValue += ', ' + skill.label + ' ' + skill.classification + ' ' + skill.category;
        }
      });
    } else {
      displayValue = value;
    }
    return displayValue;
  }

  optionClicked(event: Event, skill: Skill) {
    event.stopPropagation();
    this.toggleSelection(skill);
  }

  toggleSelection(skill: Skill) {
    skill.selected = !skill.selected;
    if (skill.selected) {
      this.selectedSkills.push(skill);
    } else {
      // tslint:disable-next-line:max-line-length
      const i = this.selectedSkills.findIndex(value => value.label === skill.label && value.classification === skill.classification && value.category === skill.category);
      this.selectedSkills.splice(i, 1);
    }

    this.skills.setValue(this.selectedSkills);
  }

}



