import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
// Service to call functions between components
export class SharedService {
  private subject = new Subject<any>();
// Function to call a function
  callFunctionEvent(){
    this.subject.next();
  }
// Function to get the call
  getTheCallFunctionEvent(): Observable<any>{
    return this.subject.asObservable();
  }
}
