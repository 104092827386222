<br>
<div class="col-12">
  <div class="row">
    <button mat-raised-button class="col-md-4 button_create" class="buttoncreate" color="primary"
            (click)="openDialog({}, 'create')">{{ "Entities.Skills.CreateButton" | translate }}</button>
    <mat-form-field class="col-md-7 filter">
      <mat-label>{{ "Entities.Global.Filter.Title" | translate }}</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="{{ 'Entities.Global.Filter.Text' | translate }}"
             #input>
      <mat-icon aria-label="Search_icon">search</mat-icon>
    </mat-form-field>
  </div>
  <br>
  <br>
  <div class="col-12 data-table">
    <table mat-table matTableResponsive [dataSource]="dataSource" matSort>

      <!-- Active Column -->
      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "Entities.Global.Fields.Active" | translate }}</th>
        <td mat-cell *matCellDef="let skill">
          <mat-slide-toggle [checked]="skill.active" (change)="setUse($event,skill)"></mat-slide-toggle>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "Entities.Global.Fields.Name" | translate }}</th>
        <td mat-cell *matCellDef="let skill"> {{skill.label}} </td>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "Entities.Skills.Fields.Type" | translate }}</th>
        <td mat-cell *matCellDef="let skill">
          <option
            *ngIf="skill.category == 'technical'">{{ 'Entities.Skills.Fields.Types.Technical' | translate }}</option>
          <option
            *ngIf="skill.category == 'functional'">{{ 'Entities.Skills.Fields.Types.Functional' | translate }}</option>
        </td>
      </ng-container>

      <!-- Category Column -->
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "Entities.Skills.Fields.Category" | translate }}</th>
        <td mat-cell *matCellDef="let skill">
          <option
            *ngIf="skill.classification == 'tool'">{{ 'Entities.Skills.Fields.Categories.Tool' | translate }}</option>
          <option
            *ngIf="skill.classification == 'role'">{{ 'Entities.Skills.Fields.Categories.Role' | translate }}</option>
          <option
            *ngIf="skill.classification == 'language'">{{ 'Entities.Skills.Fields.Categories.Language' | translate }}</option>
          <option
            *ngIf="skill.classification == 'concept'">{{ 'Entities.Skills.Fields.Categories.Concept' | translate }}</option>
          <option
            *ngIf="skill.classification == 'application'">{{ 'Entities.Skills.Fields.Categories.Application' | translate }}</option>
        </td>
      </ng-container>

      <!-- Last Update Date Column -->
      <ng-container matColumnDef="last_update_date">
        <th mat-header-cell *matHeaderCellDef
            mat-sort-header>{{ "Entities.Global.Fields.LastUpdateDate" | translate }}</th>
        <td mat-cell *matCellDef="let skill"> {{skill.last_update_date.split('T')[0]}} </td>
      </ng-container>

      <!-- Last Update User Column -->
      <ng-container matColumnDef="last_update_user">
        <th mat-header-cell *matHeaderCellDef
            mat-sort-header>{{ "Entities.Global.Fields.LastUpdateUser" | translate }}</th>
        <td mat-cell *matCellDef="let skill"> {{skill.last_update_user.split('@')[0]}} </td>
      </ng-container>

      <!-- Buttons Column -->
      <ng-container matColumnDef="operations">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let skill">
          <button class="button_edit" mat-icon-button
                  (click)="openDialog(skill,'edit')">{{ "Entities.Global.Fields.Operations.Edit" | translate }}</button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let skill; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">{{ "Entities.Global.Filter.NoMatch" | translate }} "{{input.value}}"</td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" style="display: flex; justify-content: center"></mat-paginator>
  </div>

</div>


