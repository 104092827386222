<br />
<div class="col-12">
  <div class="row">
    &nbsp;&nbsp;<button mat-raised-button class="col-md-4 button_create" class="buttoncreate" color="primary"
      (click)="openDialog(usergroup, 'create')">{{ 'Entities.Usergroups.CreateButton' | translate }}
    </button>
    <mat-form-field class="col-md-7 filter">
      <mat-label>{{ "Entities.Global.Filter.Title" | translate }}</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="{{ 'Entities.Global.Filter.Text' | translate }}"
        #input>
      <mat-icon aria-label="Search_icon">search</mat-icon>
    </mat-form-field>
  </div>
  <br />
  <br />
  <div class="col-12 data-table">
    <table mat-table matTableResponsive [dataSource]="dataSource" matSort>

      <!-- Active Column -->
      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "Entities.Global.Fields.Active" | translate }}</th>
        <td mat-cell *matCellDef="let usergroup">
          <mat-slide-toggle [checked]="usergroup.active" (change)="setUse($event,usergroup)"></mat-slide-toggle>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="label">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "Entities.Global.Fields.Name" | translate }}</th>
        <td mat-cell *matCellDef="let usergroup"> {{usergroup.label}} </td>
      </ng-container>

      <!-- Last Update Date Column -->
      <ng-container matColumnDef="last_update_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "Entities.Global.Fields.LastUpdateDate" | translate }}
        </th>
        <td mat-cell *matCellDef="let usergroup"> {{usergroup.last_update_date.split('T')[0]}} </td>
      </ng-container>

      <!-- Last Update User Column -->
      <ng-container matColumnDef="last_update_user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "Entities.Global.Fields.LastUpdateUser" | translate }}
        </th>
        <td mat-cell *matCellDef="let usergroup"> {{usergroup.last_update_user.split('@')[0]}} </td>
      </ng-container>

      <!-- Manager Column -->
      <ng-container matColumnDef="manager">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Manager
        </th>
        <td mat-cell *matCellDef="let usergroup">
          <div *ngIf="usergroup.manager !== undefined">
            {{usergroup.manager.USER_ID}}
<!--            {{usergroup.manager.USER_ID.split('@')[0]}}-->
          </div>
        </td>
      </ng-container>

      <!-- Parent Column -->
      <ng-container matColumnDef="parent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Parent
        </th>
        <td mat-cell *matCellDef="let usergroup">
          <div *ngIf="usergroup.parent !== undefined">
            {{usergroup.parent.label}}
          </div>
        </td>
      </ng-container>

      <!-- Buttons Column -->
      <ng-container matColumnDef="operations">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let usergroup">
          <button class="button_edit" mat-icon-button
            (click)="openDialog(usergroup,'edit')">{{ "Entities.Global.Fields.Operations.Edit" | translate }}</button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let usergroup; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">{{ "Entities.Global.Filter.NoMatch" | translate }} "{{input.value}}"</td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" style="display: flex; justify-content: center"></mat-paginator>
  </div>

</div>
