import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import {RatingDateAverage} from '../../../models/rating-date-average.model';

const DATA: RatingDateAverage[] = [
  {
    date: '2021-02-01',
    average: 0
  },
  {
    date: '2021-01-01',
    average: 0.42995
  },
  {
    date: '2020-12-01',
    average: 1.19482
  },
  {
    date: '2020-11-01',
    average: 1.94295
  },
  {
    date: '2020-10-01',
    average: 1.34928
  },
  {
    date: '2020-09-01',
    average: 1.52904
  },
  {
    date: '2020-08-01',
    average: 1.94982
  },
  {
    date: '2020-07-01',
    average: 2.04924
  },
  {
    date: '2020-06-01',
    average: 2.59252
  },
  {
    date: '2020-05-01',
    average: 1.75920
  },
  {
    date: '2020-04-01',
    average: 2.52562
  },
  {
    date: '2020-03-01',
    average: 2.20421
  }
];

@Component({
  selector: 'app-dashboard-user-abilities-evolution-chart',
  templateUrl: './dashboard-user-abilities-evolution-chart.component.html',
  styleUrls: ['./dashboard-user-abilities-evolution-chart.component.css']
})
export class DashboardUserAbilitiesEvolutionChartComponent implements OnInit {

  public lineChartData: ChartDataSets[] = [
    { data: DATA.map(x => x.average), label: 'Average Rating' },
  ];
  public lineChartLabels: Label[] = DATA.map(x => x.date);
  public lineChartOptions: ChartOptions = {
    responsive: true,
  };
  public lineChartColors: Color[] = [
    {
      backgroundColor: 'rgba(0,158,224,0.3)',
    },
  ];
  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';
  public lineChartPlugins = [];

  constructor() {
  }

  ngOnInit() {
  }

}
