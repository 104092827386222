<mat-icon (click)="closeDialog()" type="button" style="color: #009EE0; float: right;" aria-label="Cancel_icon">cancel
</mat-icon>

<div class="title">
  <h2 mat-dialog-title>{{ "Dashboards.Manager.AddNeedsDialog.DialogTitle" | translate }} </h2>
</div>
<br/>

<mat-dialog-content>
  <form>
    <div class="title">
      <h3>{{ "Dashboards.Manager.AddNeedsDialog.SelectTitle" | translate }} </h3>
    </div>
    <mat-form-field appearance="fill">
      <mat-label>{{ "Entities.Usergroups.Title" | translate }}</mat-label>
      <mat-select [formControl]="usergroups" (selectionChange)="retrieveAllSkillsName()">
        <mat-option *ngFor="let Usergroup of Usergroups" [value]="Usergroup">{{Usergroup.label}}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <form>
    <div class="title">
      <h3>{{ "Dashboards.Manager.AddNeedsDialog.SelectTitle2" | translate }} </h3>
    </div>
    <mat-form-field appearance="fill">
      <mat-label>{{ "Entities.Applications.Title" | translate }} </mat-label>
      <mat-select [formControl]="apps" (selectionChange)="retrieveAllSkillsName()">
        <mat-option>--</mat-option>
        <mat-option *ngFor="let App of Apps" [value]="App">{{App.label}}</mat-option>
      </mat-select>
    </mat-form-field>
    <ng-container *ngIf="Skills.length > 0">
      <div class="title">
        <label class="input-label">{{ "Entities.Skills.Dialog.SelectTitle" | translate }}</label>
      </div>
      <mat-form-field appearance="fill">
        <input type="text" placeholder="Enter text to find skill" aria-label="Enter text to find skill" matInput
               [matAutocomplete]="auto" [formControl]="skillsform" [disabled]="Skills.length === 0">
      </mat-form-field>

      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let skill of filteredSkills | async" [value]="selectedSkills">
          <div (click)="optionClicked($event, skill)">
            <mat-checkbox [checked]="skill.selected" (change)="toggleSelection(skill)"
                          (click)="$event.stopPropagation()">
              {{skill.label}} - {{skill.category}} - {{skill.classification}}
            </mat-checkbox>
          </div>
        </mat-option>
      </mat-autocomplete>
    </ng-container>

    <div [formGroup]="mainFormGroup">
      <mat-vertical-stepper formArrayName="stepData" #stepper="matVerticalStepper" [selectedIndex]="currentStep">
        <mat-step *ngFor="let stepForm of mainFormGroup.get('stepData')['controls']; let i = index"
                  [stepControl]="stepForm">
          <div [formGroup]="stepForm">
            <ng-template matStepLabel *ngIf="this.skillsform.value != null && this.skillsform.value[i] != null">
              {{ this.skillsform.value[i].label }} </ng-template>
            <div class="valueandoperator">
              <div class="value">
                <label class="input-label">{{ "Dashboards.Manager.AddNeedsDialog.SelectValueLabel" | translate }}</label>
                <mat-select formControlName="firstCtrl">
                  <mat-option value="1">{{ "Dashboards.StarRating.1" | translate }}</mat-option>
                  <mat-option value="2">{{ "Dashboards.StarRating.2" | translate }} </mat-option>
                  <mat-option value="3">{{ "Dashboards.StarRating.3" | translate }}</mat-option>
                  <mat-option value="4">{{ "Dashboards.StarRating.4" | translate }}</mat-option>
                </mat-select>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <div class="operator">
                <label class="input-label">{{ "Dashboards.Manager.AddNeedsDialog.SelectOperatorLabel" | translate }}</label>
                <mat-select formControlName="secondCtrl">
                  <mat-option value=">">></mat-option>
                  <mat-option value="=">=</mat-option>
                  <mat-option value=">=">>=</mat-option>
                </mat-select>
              </div>
            </div>
            <div class="title">
              <label class="input-label">{{ "Dashboards.Manager.AddNeedsDialog.SelectResourcesLabel" | translate }}</label>
            </div>
            <mat-form-field style="background-color: #EEEFF0">
              <input matInput type="number" formControlName="thirdCtrl"/>
            </mat-form-field>
          </div>
        </mat-step>
      </mat-vertical-stepper>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button class="mat-raised-button button_cancel col-md-3"
          (click)="closeDialog()">{{ "Buttons.Cancel" | translate }}</button>
  &nbsp;&nbsp;&nbsp;&nbsp;
  <button class="mat-raised-button button_save col-md-3"
  [disabled]="!choiceMade()" (click)="saveRating()">{{ "Buttons.Save" | translate }}</button>
</mat-dialog-actions>

