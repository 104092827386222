<div class="col-12 data-table">

  <mat-form-field class="col-md-12 filter">
    <mat-label>{{ "Entities.Global.Filter.Title" | translate }}</mat-label>
    <input matInput style="background-color: rgb(235, 233, 233);" (keyup)="applyFilter($event)" placeholder="{{ 'Entities.Global.Filter.Text' | translate }}"
           #input>
    <mat-icon aria-label="Search_icon">search</mat-icon>
  </mat-form-field>

  <table mat-table matTableResponsive [dataSource]="dataSource" matSort>

    <!-- Name Column -->
    <ng-container matColumnDef="skill_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "Dashboards.User.RatingsTable.SkillName" | translate }}</th>
      <td mat-cell *matCellDef="let ability">{{ability.skill_label}}</td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="skill_type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "Dashboards.User.RatingsTable.SkillType" | translate }}</th>
      <td mat-cell *matCellDef="let ability">{{ability.skill_category}}</td>
    </ng-container>

    <!-- Category Column -->
    <ng-container matColumnDef="skill_category">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "Dashboards.User.RatingsTable.SkillCategory" | translate }}</th>
      <td mat-cell *matCellDef="let ability">{{ability.skill_classification}}</td>
    </ng-container>

    <!-- Last Rating Date Column -->
    <ng-container matColumnDef="last_rating_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "Dashboards.User.RatingsTable.LastHistoryDate" | translate }}</th>
      <td mat-cell *matCellDef="let ability">
        <mat-chip-list>
          <mat-chip selected [color]="attributeColor(ability.last_rating_date.split('T')[0])">
            {{ ability.last_rating_date.split('T')[0] }}
          </mat-chip>
        </mat-chip-list>
      </td>
    </ng-container>

    <!-- Last Rating Date Column -->
    <ng-container matColumnDef="last_rating_value">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "Dashboards.User.RatingsTable.LastHistoryValue" | translate }}</th>
      <td mat-cell *matCellDef="let ability">
        <app-star-rating [rating]="ability.last_rating_value" (ratingUpdated)="openDialog(ability, $event)"></app-star-rating>
      </td>
    </ng-container>

    <!-- Button Column -->
    <ng-container matColumnDef="operations">
      <th mat-header-cell *matHeaderCellDef>
      </th>
      <td mat-cell *matCellDef="let ability">
        <button mat-icon-button style="color: #009EE0; float: right;">
          <mat-icon (click)="openDisableDialog(ability, $event)">delete_forever</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let skill; columns: displayedColumns;"></tr>

  </table>

  <mat-paginator *ngIf="mobile" [pageSize]="2" [hidePageSize]="true" style="display: flex; justify-content: center"></mat-paginator>
  <mat-paginator *ngIf="!mobile" [pageSize]="10" [hidePageSize]="true" style="display: flex; justify-content: center"></mat-paginator>
</div>
