<div class="starrateglobal">
    <div class="starrate" (mouseover)="setStars(1)" (mouseleave)="setStars(0)" (click)="setStarsClick(1)" #tooltip="matTooltip"
    matTooltip='{{ "GetStarted.Standards.3" | translate }}'>
      <span *ngIf="this.stars > 0" class="material-icons" [ngStyle]="{'color':'#009EE0'}" (onclick)="setStarsClick(1)">
        star
      </span>
      <span *ngIf="this.stars == 0" class="material-icons" [ngStyle]="{'color':'#666666'}">
        star_border
      </span>
    </div>
    <div class="starrate" (mouseover)="setStars(2)" (mouseleave)="setStars(0)" (click)="setStarsClick(2)" #tooltip="matTooltip"
    matTooltip='{{ "GetStarted.Standards.4" | translate }}'> 
      <span *ngIf="this.stars > 1" class="material-icons" [ngStyle]="{'color':'#009EE0'}" (onclick)="setStarsClick(2)">
        star
      </span>
      <span *ngIf="1 >= this.stars" class="material-icons" [ngStyle]="{'color':'#666666'}">
        star_border
      </span>
    </div>
    <div class="starrate" (mouseover)="setStars(3)" (mouseleave)="setStars(0)" (click)="setStarsClick(3)" #tooltip="matTooltip"
    matTooltip='{{ "GetStarted.Standards.5" | translate }}'>
      <span *ngIf="this.stars > 2" class="material-icons" [ngStyle]="{'color':'#009EE0'}" (onclick)="setStarsClick(3)">
        star
      </span>
      <span *ngIf="2 >= this.stars" class="material-icons" [ngStyle]="{'color':'#666666'}">
        star_border
      </span>
    </div>
    <div class="starrate" (mouseover)="setStars(4)" (mouseleave)="setStars(0)" (click)="setStarsClick(4)" #tooltip="matTooltip"
    matTooltip='{{ "GetStarted.Standards.6" | translate }}'>
      <span *ngIf="this.stars > 3" class="material-icons" [ngStyle]="{'color':'#009EE0'}" (onclick)="setStarsClick(4)">
        star
      </span>
      <span *ngIf="3 >= this.stars" class="material-icons" [ngStyle]="{'color':'#666666'}" (onclick)="setStarsClick(4)">
        star_border
      </span>
    </div>