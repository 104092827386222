import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, empty} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import { UserService } from './entities/user.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  // Constants retrieved from environment
  readonly API_BASE_URL: string = environment.API_BASE_URL;
  readonly WEBSITE_BASE_URL: string = environment.WEBSITE_BASE_URL;
  language: any;


  constructor( private http: HttpClient,
    private userService:UserService,
    private translateService:TranslateService ) {  }



  /**
   * If error, remove token from the session and redirect to the website base URL
   */
  private doError(service: string, error: any, caught: any) {
    console.log("An error occurred when requesting API '" + service + "':");
    console.log(error);
    sessionStorage.removeItem('authToken');
    alert("An error occurred when requesting API '" + service + "'");
    window.location.href = this.WEBSITE_BASE_URL;
    return empty();
  }

  /**
   * Call the GET service with the specified parameters
   */
  private callGetService(service: string, get_parameters: string) {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json', Authorization: sessionStorage.getItem('authToken')})
    };
    const serviceUrl = this.API_BASE_URL + service + get_parameters;

    this.userService.getProfile().subscribe(
      (response:any) => {
        this.language = response.translation_language;
      }
    );
    console.log(this.language);
    this.translateService.use(this.language);

    return this.http.get<any>(serviceUrl, httpOptions).pipe(
      map((response: any): String => {
      return response;
    }), catchError((error, caught) => {
      return this.doError(service, error, caught);
    }));
  }

  /**
   * List AWS Resources Data
   */
  callConnection(): Observable<any> {
    const service = 'connection';
    return this.callGetService(service, '');
  }
}
