<br>

<!-- <h1 class="title col">{{ "Manage_Entities" | translate }}</h1>-->

<mat-tab-group color="primary" mat-align-tabs="start">

  <mat-tab label="{{ 'Entities.Usergroups.Title' | translate }}">
    <app-usergroups></app-usergroups>
  </mat-tab>
  <mat-tab label="{{ 'Entities.Applications.Title' | translate }}">
    <app-applications></app-applications>
  </mat-tab>
  <mat-tab label="{{ 'Entities.Skills.Title' | translate }}">
    <app-skills></app-skills>
  </mat-tab>

</mat-tab-group>
