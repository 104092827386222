<br />

<div style="text-align: center; font-size: 150%">
  <strong>{{ "MatrixOptions.MatrixExport.Header" | translate }}</strong>
</div>

<br />
<br />

<div style="text-align: center">
  <p>{{ "MatrixOptions.MatrixExport.Text" | translate }}</p>

  <br />
  <br />

  <div class="col-12">
    <input type="button" class="btn btn-success container" value="{{ 'MatrixOptions.MatrixExport.ExportYourTeamButton' | translate }}">
  </div>

  <br />

  <div class="col-12">
    <input type="button" class="btn btn-success container" value="{{ 'MatrixOptions.MatrixExport.ExportAllUsergroupsButton' | translate }}">
  </div>
</div>
